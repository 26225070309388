import { navigate } from 'gatsby';
import * as React from 'react';
import { useQueryParam, StringParam } from 'use-query-params';
import { OtpFlow } from '../../components';
import Layout from '../../components/Layout';
import useAuthenticated from '../../hooks/useAuthenticated';
import useEnsureWallet from '../../hooks/useEnsureWallet';
import { createOtp } from '../../services/otp';

export default function Otp() {
  useAuthenticated();
  useEnsureWallet();
  const [action] = useQueryParam('action', StringParam);
  const [wallet] = useQueryParam('wallet', StringParam);
  const [phone] = useQueryParam('phone', StringParam);
  const [loading, setLoading] = React.useState(false);

  // send create otp action
  React.useEffect(() => {
    async function run() {
      if (loading) return;
      setLoading(true);
      try {
        const resp = await createOtp(
          'create_user',
          'phone',
          { phone, wallet },
          `${action}/?id=${wallet}&phone=${phone}`
        );
        navigate(`verify?id=${resp.id}`);
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    }
    run();
  }, [phone]);

  return (
    <>
      <OtpFlow phone={phone} />
      {/* <Toast
        status={transaction.paired && transaction.paid ? "normal" : "warning"}
        visible={notification}
        onClose={() => setNotification(false)}
        title={
          transaction.paired && transaction.paid ? "Change Sent!" : "Failed"
        }
        message={message || transaction.sender_message}
      /> */}
    </>
  );
}
