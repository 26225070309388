import { ISession } from 'business/models/session';
import { DocumentMissing } from 'grommet-icons';
import { Box } from 'grommet/components/Box';
import { Spinner } from 'grommet/components/Spinner';
import { Text } from 'grommet/components/Text';
import React from 'react';
import { TransactionTile } from '../TransactionTile';

// if (table)
// return (
//   <Box
//     align="stretch"
//     justify="center"
//     overflow={{ vertical: "scroll" }}
//     pad={{ vertical: "medium" }}
//   >
//     <DataTable
//       columns={[
//         { header: "Time", property: "created_at", primary: true },
//         { header: "Wallet", property: "wallet.title" },
//         {
//           header: "Receiver",
//           property: "initiator.id",
//           render: (datum) => (
//             <Text>
//               {datum.initator ? datum.initiator.verified_phone || datum.initiator.name : ''}
//             </Text>
//           ),
//         },
//         {
//           header: "Sender",
//           property: "recipient.name",
//           render: (datum) => (
//             <Text>
//               {datum.recipient.verified_phone || datum.recipient.name}
//             </Text>
//           ),
//         },
//         { property: "amount", header: "Amount", units: "NGN" },
//       ]}
//       data={transactions?.map((t) => ({
//         ...t,
//         amount: t.amount / 100,
//         wallet_title: t.wallet?.title || "main",
//         created_at: DateTime.fromISO(t.created_at).toFormat(
//           "dd, MMM yy, h:mm a",
//         ),
//       }))}
//       paginate={false}
//       sortable
//       sort={{ property: "Time", direction: "desc" }}
//       fill="vertical"
//       placeholder={
//         loading ? (
//           <Box
//             fill
//             align="center"
//             justify="center"
//             direction="row"
//             pad="large"
//             gap="small"
//             background={{ color: "background-front", opacity: "strong" }}
//           >
//             <Spinner />
//             <Text weight="bold">Loading ...</Text>
//           </Box>
//         ) : (
//           <div />
//         )
//       }
//     />
//   </Box>
// );
export interface TransactionListProps {
  session?: ISession;
  transactions: [];
  loading?: boolean;
  onSelect?: () => {};
}
export function TransactionList({
  session,
  transactions,
  loading,
  onSelect
}: TransactionListProps) {
  const onSelectTransaction = (transaction) => (event) => {
    onSelect(transaction);
  };
  if (loading) {
    return (
      <Box justify="center" align="center" fill>
        <Spinner />
      </Box>
    );
  }
  if (!loading && (!transactions || transactions.length === 0)) {
    return (
      <Box
        pad="xlarge"
        justify="center"
        align="center"
        gap="small"
        margin={{ horizontal: 'medium', vertical: 'small' }}
        background="light-1"
        round="small">
        <DocumentMissing /> <Text>You do not have any recent activity</Text>
      </Box>
    );
  }
  if (session && transactions.length > 0)
    return transactions?.map((datum) => (
      <TransactionTile
        onClick={onSelectTransaction(datum)}
        key={datum.id}
        session={session}
        transaction={datum}
      />
    ));
  return <div />;
}
