import { UnauthenticatedModalInvite } from 'app/design/UnauthenticatedModalInvite';
import { Member } from 'business/models/member';
import React from 'react';

export interface InviteScreenProps {
  member: Member;
  loading: boolean;
  onChange;
  onJoin?: (data) => {};
}

export function InviteScreen({ member, onJoin, loading, onChange }: InviteScreenProps) {
  return (
    <div className="mx-auto flex px-5 sm:pt-10 lg:my-auto">
      <UnauthenticatedModalInvite
        member={member}
        onChange={onChange}
        onSubmit={onJoin}
        loading={loading}
      />
    </div>
  );
}

export default InviteScreen;
