import React from 'react';

export const Input = React.forwardRef(({ label, error, ...props }: InputProps, ref) => (
  <div className="form-control flex flex-grow flex-col">
    {label && (
      <label className="label" htmlFor={props.name}>
        <span className="label-text">{label}</span>
      </label>
    )}
    <input {...props} className="input input-bordered" ref={ref} />
    <label className="label">
      {error && <span className="label-text-alt text-error">{error?.message}</span>}
    </label>
  </div>
));

Input.defaultProps = {
  label: ''
};

export interface InputProps {
  label?: string;
  error;
}

export default Input;
