import { Button, Card, CardBody, List, Text, Box, Image } from 'grommet';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { RangeInput } from 'components/RangeInput';
import clsx from 'clsx';
import Shopping from '../../images/shopping.png';
import { createPayment } from '../../services/payment';

export interface BuyCreditProps {
  items?;
  currency?: string;
  wallet?: any;
  onCreditBought?;
}

function BuyCreditTile({ currency, item: { price, name }, busy, onBuy }) {
  return (
    <Card background="brand" margin="small">
      <CardBody
        direction="row"
        justify="between"
        align="center"
        pad={{ horizontal: 'medium', vertical: 'medium' }}
        gap="small">
        {/* <Box width="70px" alignSelf='start'>
      <Image src={Shopping} fit="contain" />
    </Box> */}
        <Box width="small">
          <Text weight="bolder" size="large">
            {name}
          </Text>
          <Text size="small" weight="bold">
            {currency} {price / 100}
          </Text>
        </Box>
        <Box
          direction="column"
          justify="between"
          align="end"
          gap="xsmall"
          margin={{ left: 'medium' }}>
          <Button
            secondary
            label={<Text margin={{ horizontal: 'medium' }}>Buy</Text>}
            onClick={onBuy}
            disabled={busy}
          />
        </Box>
      </CardBody>
    </Card>
  );
}

export function BuyCredit({ onCreditBought, wallet, items, currency }: BuyCreditProps) {
  const [busy, setBusy] = useState(false);
  const [amount, setamount] = useState(0);
  const onBuyCredit = async (item) => {
    if (busy) return;
    setBusy(true);
    try {
      const resp = await toast.promise(
        createPayment({ amount: `${amount * 100}`, wallet: wallet.id, redirectURL: location.href }),
        {
          loading: 'Working...',
          success: <b>Redirecting to payment partner</b>,
          error: <b>Could not get payment link.</b>
        }
      );
      onCreditBought(resp);
    } catch (error) {
      toast.error(error.message);
    }

    setBusy(false);
  };
  const onLimit = (field, v) => {
    // setValue({ ...value, [field]: v });
    setamount(parseInt(v));
  };

  return (
    <div className="flex w-full flex-col items-center justify-center p-5">
      <RangeInput
        min={0}
        max={5000}
        step={100}
        defaultValue={amount}
        onChange={(v) => onLimit('max_customer_credits', v.target.value)}
      />
      <button
        type="button"
        className={clsx(
          'btn btn-outline btn-xs sm:btn-sm md:btn-md lg:btn-lg',
          busy && 'loading btn-disabled'
        )}
        onClick={() => onBuyCredit({})}>
        TOP UP
      </button>
      {/* <List
        fill
        pad="medium"
        border={false}
        data={items}
        // background="light-2"
      >
        {(item: { price; name }): JSX.Element => (
          <BuyCreditTile
            currency={currency}
            item={item}
            onBuy={() => onBuyCredit(item)}
            busy={busy}
          />
        )}
      </List> */}
    </div>
  );
}

BuyCredit.defaultProps = {
  currency: 'NGN',
  items: [
    { name: '1k Credits', price: 100000 },
    { name: '2k Credits', price: 200000 },
    { name: '5k Credits', price: 500000 }
  ]
};
