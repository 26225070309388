import {
  Card, CardBody, Box, Paragraph, Text,
} from 'grommet';
import { UserExpert } from 'grommet-icons';
import React from 'react';
import tw from 'twin.macro';

export interface SmsCardProps {
  title?: string
  body?: string
}

const DummyText = tw.div`w-full bg-gray-100 rounded-md h-10`;
const DummyBody = tw.div`w-full bg-gray-100 rounded-md h-32`;
const Body = tw.div`flex-row flex px-2 py-4`;
const IconContainer = tw.div`px-4 bg-gray-200 h-7 rounded-lg py-4 pl-5 mx-1`;

export const SmsCard: React.FC<SmsCardProps> = ({ title, body }) => (
  <Card width={{ min: 'small', max: 'medium' }} pad={{ horizontal: 'medium', vertical: 'medium' }} height={{ min: 'small' }}>
    <CardBody pad="0">
      <Body>
        {/* <Box pad="small" margin={{ right: "small" }} background="light-2" justify="center" align="center" round="medium" height="40px"> */}
        <IconContainer>
          <UserExpert />
        </IconContainer>
        {/* </Box> */}
        <Box gap="small" width="medium" pad={{ left: 'medium' }}>
          {title ? <Text weight="bold">{title}</Text> : <DummyText />}
          {body ? <Paragraph style={{ whiteSpace: 'pre-line' }} weight="bold">{body}</Paragraph> : <DummyBody />}
        </Box>
      </Body>
    </CardBody>
  </Card>
);
