import * as React from 'react';
import { Link } from 'gatsby';

export default function DSG(props) {
  return (
    <>
      <Link to="/">Home</Link>
      <br />
      <h1>DSG: Deferred Static Generation</h1>
    </>
  );
}
