import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

export interface FileInputProps extends React.HTMLProps<HTMLInputElement> {
  title?: string;
}

export function FileInput({ title, onChange, value, ...rest }: FileInputProps) {
  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState();
  const onDrop = useCallback((acceptedFiles) => {
    if (!acceptedFiles || acceptedFiles.length === 0) {
      setSelectedFile(undefined);
      return;
    }
    setSelectedFile(acceptedFiles[0]);
    if (onChange) onChange({ target: { files: acceptedFiles } });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/png': ['.png'],
      'image/jpg': ['.jpg', '.jpeg']
    }
  });

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  useEffect(() => {
    if (value?.files?.length > 0) {
      if (typeof value.files[0] === 'string') {
        setPreview(value.files[0]);
      }
    }
  }, [value]);

  const interceptOnChange = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }

    setSelectedFile(e.target.files[0]);
    if (onChange) onChange({ target: { files: e.target.files } });
  };

  return (
    <div {...getRootProps({ className: 'dropzone' })}>
      <label className="block text-sm font-medium text-gray-700">{title}</label>
      <div className="mt-1 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
        <div className="flex flex-col gap-4 space-y-1 text-center">
          {preview ? (
            <img
              src={preview}
              alt="selected file"
              className="mx-auto max-h-32 max-w-full rounded-lg"
            />
          ) : (
            <svg
              className="mx-auto h-12 w-12 text-gray-400"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 48 48"
              aria-hidden="true">
              <path
                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
          <div className="flex text-center text-sm text-gray-600">
            <label
              htmlFor="file-upload"
              className="relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500">
              <span>Upload a file</span>
              <input
                className="sr-only"
                onChange={interceptOnChange}
                {...rest}
                {...getInputProps()}
              />
            </label>
            <p className="dropzone-content pl-1">or drag and drop</p>
          </div>
          <p className="text-xs text-gray-500">PNG, JPG up to 1MB</p>
        </div>
      </div>
    </div>
  );
}

FileInput.defaultProps = {
  title: 'Cover photo'
};
