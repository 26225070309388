import { Box } from 'grommet';
import React from 'react';
import tw from 'twin.macro';

const ListContainer = tw.div`flex overflow-x-scroll pb-2 pt-2 scrollbar-hide w-full`;
const ListInner = tw.div`flex flex-nowrap md:ml-5 ml-5 gap-4 py-4 px-6`;
export interface HorizontalListProps {}

export const HorizontalList: React.FC<HorizontalListProps> = ({ children, ...props }) => (
  <Box {...props}>
    <ListContainer>
      <ListInner>{children}</ListInner>
    </ListContainer>
  </Box>
);
