import React, { useState, useEffect, createContext } from 'react';
import axios from 'axios';

export const ErrorContext = createContext();

function ErrorProvider({ children }) {
  const [lastError, setLastError] = useState(null);

  useEffect(() => {
    const requestInterceptor = axios.interceptors.request.use((config) => {
      setLastError(null);
      return config;
    });

    const responseInterceptor = axios.interceptors.response.use(
      (response) => {
        setLastError(null);
        return response;
      },
      (error) => {
        if (!error.response) {
          setLastError(error);
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, []);

  return (
    <ErrorContext.Provider value={{ lastError }}>
      {lastError && <p>Network Error. Please try again later.</p>}
      {children}
    </ErrorContext.Provider>
  );
}

export default ErrorProvider;
