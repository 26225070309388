import clsx from 'clsx';
import React from 'react';
import PhoneInputWithCountry, { isValidPhoneNumber } from 'react-phone-number-input';
import PhoneInputWithCountryRHF from 'react-phone-number-input/react-hook-form';
import { define } from 'superstruct';

export function PhoneInput({ control, ...props }: PhoneInputProps) {
  if (control)
    return (
      <PhoneInputWithCountryRHF
        numberInputProps={{
          className: clsx('input input-bordered w-full')
        }}
        className="w-full"
        control={control}
        international
        defaultCountry="NG"
        {...props}
      />
    );
  return (
    <PhoneInputWithCountry
      numberInputProps={{
        className: clsx('input input-bordered w-full')
      }}
      className="flex w-full flex-row"
      international
      defaultCountry="NG"
      {...props}
    />
  );
}

export const PhoneValidator = define('phone', (value) => {
  if (!value) return { message: 'required' };
  if (!isValidPhoneNumber(value as string)) {
    return { message: 'Please enter a valid phone number' };
  }
  return true;
});

export interface PhoneInputProps extends React.HTMLProps<HTMLInputElement> {
  disabled?: boolean;
}
PhoneInput.defaultProps = {
  disabled: false
};
