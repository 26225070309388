import * as React from 'react';
import { MailOption } from 'grommet-icons';
import { useLocalStorage } from 'usehooks-ts';
import { Box, Heading, Form, TextInput, FormField, Spinner, MaskedInput, Anchor } from 'grommet';
import api from 'services/api';
import Toast from 'components/Toast';
import tw from 'twin.macro';
import { navigate } from 'gatsby';
import { Button } from 'app/design/Button';

const emailMask = [
  {
    regexp: /^[\w\-_.]+$/,
    placeholder: 'example'
  },
  { fixed: '@' },
  {
    regexp: /^[\w]+$/,
    placeholder: 'my'
  },
  { fixed: '.' },
  {
    regexp: /^[\w]+$/,
    placeholder: 'com'
  }
];

export default function Home() {
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');
  const [, setSession] = useLocalStorage<common.Session>('vsn', {} as common.Session);

  async function submit() {
    setLoading(true);
    setError('');
    const resp = await api.post<common.Session>('/auth/sign_in', {
      email,
      password
    });
    if (resp.status === 200) {
      setSession(resp.data);
      api.setHeader('Authorization', `bearer ${resp.data.token}`);
      navigate('/', { replace: true });
      // window.location.reload()
    } else {
      if (resp.data?.msg) {
        setError(resp.data.msg);
      } else {
        setError('Please check your internet connection');
      }
      setLoading(false);
    }
  }

  return (
    <>
      <div className="flex h-screen flex-col bg-gray-200">
        <div className="mx-auto w-full py-8 px-2 md:w-1/2 lg:w-4/12 lg:p-8">
          <div className="rounded-lg bg-white p-8">
            <div>
              <Heading level={2}>Login to your account</Heading>
              <p>
                <span>Don&apos;t have an account? </span>
                <a
                  className="link link-hover font-bold"
                  href="/auth/register"
                  disabled={loading === true}>
                  Sign up here
                </a>
              </p>
            </div>
            <Form onSubmit={submit}>
              <Box justify="center" pad={{ vertical: 'small' }} margin={{ bottom: 'small' }}>
                <FormField label="Email" name="email" required disabled={loading}>
                  <MaskedInput
                    icon={<MailOption />}
                    mask={emailMask}
                    plain={false}
                    placeholder="Enter your email"
                    value={email}
                    name="email"
                    type="email"
                    onChange={(event) => setEmail(event.target.value)}
                  />
                </FormField>
                <FormField label="Password" name="password" required disabled={loading}>
                  <TextInput
                    plain={false}
                    placeholder="Enter your password"
                    type="password"
                    name="password"
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                  />
                </FormField>
                <Anchor
                  size="small"
                  color="dark-2"
                  label="Forgot Password?"
                  href="/auth/password_reset"
                  disabled={loading === true}
                />
              </Box>
              <Button
                className="btn btn-primary w-full"
                label="Sign In"
                active={false}
                primary
                type="submit"
                disabled={loading === true}
                icon={loading ? <Spinner /> : null}
              />
            </Form>
            <div className="my-4 flex items-center before:mt-0.5 before:flex-1 before:border-t before:border-gray-300 after:mt-0.5 after:flex-1 after:border-t after:border-gray-300">
              <p className="mx-4 mb-0 text-center font-semibold">OR</p>
            </div>
            <a
              className="btn w-full text-sm font-medium uppercase leading-snug"
              href="/auth/phone"
              role="button"
              data-mdb-ripple="true"
              data-mdb-ripple-color="light">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 320 512"
                className="mr-2 h-3.5 w-3.5">
                <path
                  fill="currentColor"
                  d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"
                />
              </svg>
              Continue with Phone
            </a>
          </div>
        </div>
      </div>
      <Toast
        status="warning"
        visible={error.length > 0}
        onClose={() => setError('')}
        title="Create account failed"
        message={error}
      />
    </>
  );
}
