import format from 'date-fns/format/index';
import { Box, DataTable, Text } from 'grommet';
import React from 'react';

export interface EngageRunListProps {
  rows?
}

export const EngageRunList: React.FC<EngageRunListProps> = ({ rows }) => {
  const columns = [
    {
      // property: "name",
      header: 'Customer',
      render: (datum) => <Text size="small" truncate="tip">{datum.last_customer_user.verified_phone}</Text>,
      // size: 'xsmall',
      pin: true,
    },
    {
      property: 'created_at',
      render: (datum) => <Text size="small" textAlign="start">{format(new Date(datum.created_at), 'MMM dd, yyyy @ hh:mm a')}</Text>,
      size: 'large',
      header: 'Created',
    },
    {
      property: 'modified_at',
      render: (datum) => <Text size="small" textAlign="start">{format(new Date(datum.modified_at), 'MMM dd, yyyy @ hh:mm a')}</Text>,
      size: 'large',
      header: 'Sent',
    },
    {
      property: 'status',
      render: (datum) => <Text size="small" truncate="tip">{datum.status}</Text>,
      header: 'Status',
    },
  ];
  return (
    <Box margin={{ bottom: 'medium' }}>
      <DataTable
        aria-describedby="contact-info-heading"
        data={rows}
        columns={columns}
        pin

      />
    </Box>
  );
};
