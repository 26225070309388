import { getInvitation } from 'services/organization';
import MemberInvite from 'logical/MemberInvite';
import { TellerDashboardScreen } from 'app/features/teller/teller-dashboard-screen';
import { PageProps } from 'gatsby';

export const getServerData = async (context: {
  headers;
  method: string;
  url: string;
  query: { inv: string };
  params;
}) => {
  const unsplit = context.url.split('?', -1);
  if (unsplit.length > 1 && unsplit[1].endsWith('member/invite')) {
    try {
      const invitation = await getInvitation(context.query.inv);
      return { invitation };
    } catch (error) {
      return {};
    }
  }
  return {};
};

export default function Index({ location }: PageProps) {
  if (location.pathname.endsWith('member/invite')) {
    return <MemberInvite />;
  }
  if (location.pathname.endsWith('/teller')) {
    return <TellerDashboardScreen />;
  }
  return <div />;
}
