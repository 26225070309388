import React from 'react';

import {
  Box, Button, Text, Tip as GTip,
} from 'grommet';
import { Info } from 'grommet-icons';

export interface TipProps {
  children?: any
  title?: any
}

function Circle({ ...rest }) {
  return (
    <Box
      animation={['fadeIn', 'pulse']} // double animation
      round="full"
      background="linear-gradient(102.77deg, #FD6FFF -9.18%, #ffdde2 209.09%)"
      {...rest}
    />
  );
}

export const Tip: React.FC<TipProps> = ({ title, children }) => (
  <GTip
    plain
    dropProps={{ align: { left: 'right' } }}
    content={(
      <Box align="start" margin={{ bottom: 'xlarge' }} pad="xsmall">
        <Box margin={{ left: 'large' }} pad="small" direction="row" gap="small" background="brand" round>
          <Info color="light-1" />
          <Text weight="bolder">{title}</Text>
        </Box>
        <Circle margin={{ left: 'medium' }} pad="small" />
        <Circle pad="xsmall" />
      </Box>
      )}
  >
    {children}
  </GTip>
);
