import React, { useState, useRef, useEffect, useCallback } from 'react';
import {
  Grid,
  Box,
  Heading,
  Paragraph,
  Text,
  Spinner,
  Anchor,
  PageHeader,
  FormField,
  Collapsible
} from 'grommet';
import { useQueryParam, StringParam } from 'use-query-params';
import { navigate } from 'gatsby';
import PinField from 'react-pin-field';
import toast from 'react-hot-toast';
import useAuthenticated from 'hooks/useAuthenticated';
import api from 'services/api';
import useEnsureWallet from 'hooks/useEnsureWallet';
import Toast from 'components/Toast';
import { PhoneMoneyForm } from 'app/design/PhoneMoneyForm';
import './app.css';
import { submitTransactionPin } from 'services/transaction';
import { getCustomerByPhone } from 'services/customers';
import { User } from 'business/models/user';
import { organizationWallet } from 'services/wallet';
import { useAuth } from 'context/auth';
import { useOrganization } from 'context/organization';
import { useModal } from 'app/hooks/useModal';
import { SuccessModal } from 'app/design/SuccessModal';

export default function Receive() {
  useAuthenticated();
  useEnsureWallet();
  const [transaction, setTransaction] = useState<common.Transaction>({} as common.Transaction);
  const [notification, setNotification] = useState(false);
  const [loading, setLoading] = useState(false);
  const [wallet, setWallet] = useState<common.Wallet>({} as common.Wallet);
  const [message, setMessage] = useState(null);
  const [pin, setPin] = useState('');
  const pinRef = useRef(null);
  const [selectedSender, setSelectedSender] = useState(null);
  const { state } = useAuth();
  const { organization } = useOrganization();
  const user = Object.assign(new User(), state.session?.data);

  const { show, hide, RenderModal } = useModal(
    {
      title: '',
      onClose: () => {
        // setfocusedData(null);
      }
    },
    'modal'
  );
  const [walletID] = useQueryParam('id', StringParam);

  async function sendPin() {
    setLoading(true);
    // pinRef?.current?.focus();
    try {
      toast.loading('verifying pin...');
      await submitTransactionPin(transaction.id, pin);
      // if ((resp as common.Transaction).paid) {
      //   navigate('/');
      // }
      toast.dismiss();
      show();
      setTransaction(null);
    } catch (error) {
      toast.error(error.message);
    }
    setPin('');
    pinRef.current.forEach((input) => (input.value = ''));
    pinRef.current[0].focus();
    // setNotification(true);
    setLoading(false);
  }

  useEffect(() => {
    if (pin.length === 4) {
      sendPin();
    }
  }, [pin]);

  const getWallet = useCallback(async () => {
    if (!organization) return;
    setLoading(true);
    try {
      const resp = await organizationWallet(walletID, user.isOwner ? null : organization.id);
      setWallet(resp);
    } catch (error) {
      window.Sentry.captureException(error);
    }

    setLoading(false);
  }, [organization, user.isOwner, walletID]);

  React.useEffect(() => {
    getWallet();
  }, [getWallet]);

  const submit = async (data) => {
    setMessage(null);
    setTransaction({});
    setLoading(true);
    const resp = await api.post<any, common.Transaction>('/store/transactionrequest', {
      ...data,
      mode: 'receive',
      wallet: wallet.id
    });
    if (resp.status === 200) {
      if (!resp.data?.pinRequired) {
        setNotification(true);
      }
      setTransaction(resp.data);
    } else {
      setNotification(true);
      setMessage(resp.data.msg);
    }
    setLoading(false);
  };
  const onChangeForm = (v) => {
    if (v.phone?.length > 0) getCustomerByPhone((v.phone as string).replace('+', ''));
  };
  return (
    <div className="w-full px-7 md:px-16">
      <Box align="stretch" justify="center" direction="column">
        <Box>
          <Anchor onClick={() => navigate('/')}>Back</Anchor>
        </Box>
        <Box height={{ min: '90px' }}>
          <PageHeader
            subtitle={loading ? <Spinner /> : `${wallet?.title} ● Wallet`}
            title="Receive credit"
          />
        </Box>
        <Box width="medium">
          <PhoneMoneyForm
            onSubmit={submit}
            loading={loading || transaction?.pinRequired}
            submitText="Receive"
            mode="receive"
            reasons={[
              { value: 'tip', label: 'Tip for a service' },
              { value: 'oweing', label: 'Oweing money' },
              { value: 'payment', label: 'Payment for a product' }
            ]}
            wallet={wallet}
            onChange={onChangeForm}
          />
        </Box>
        <Collapsible open={transaction?.pinRequired}>
          <Box width="medium" pad="small">
            <Heading level="4">Waiting for customer to verify</Heading>
            <FormField
              name="pin"
              value={pin}
              disabled={loading}
              label="Transaction PIN"
              help="Ask customer for their pin if this takes too long">
              <Box direction="row">
                <PinField
                  className="pin-field"
                  ref={pinRef}
                  validate="0123456789"
                  length={4}
                  inputMode="numeric"
                  onChange={setPin}
                />
              </Box>
            </FormField>
          </Box>
        </Collapsible>
      </Box>
      <Toast
        status={transaction?.paired && transaction?.paid ? 'normal' : 'warning'}
        visible={notification}
        onClose={() => setNotification(false)}
        title={transaction?.paired && transaction?.paid ? 'Change Sent!' : 'Failed'}
        message={message || transaction?.sender_message}
      />
      <RenderModal>
        <SuccessModal
          onClose={hide}
          title="Success"
          message={
            transaction?.id &&
            `${
              transaction.initiator.profile?.firstName ||
              transaction.initiator?.firstName ||
              transaction.initiator?.verified_phone
            } has received ${transaction.amount / 100} credits in their ${wallet?.title} wallet`
          }
        />
      </RenderModal>
    </div>
  );
}
