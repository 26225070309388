import {
  Box, Form, Heading, Paragraph,
} from 'grommet';
import React from 'react';

export interface WizardFormProps {
  steps: forms.WizardStep[],
  onChange?
  onSubmit?
  loading?
}

export const WizardForm: React.FC<WizardFormProps> = ({
  value,
  steps, onChange, onSubmit, loading,
}) => (
  <Form aria-disabled={loading} onChange={onChange} onSubmit={onSubmit} value={value}>
    {
        steps.map(({ title, description, inputs }) => (
          <Box fill>
            {inputs}
          </Box>
        ))
      }
  </Form>
);
