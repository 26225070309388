import api from './api';

export async function createPayment(
  payment: common.Payment
): Promise<common.Payment | errors.APIError> {
  const response = await api.post<any, any | errors.APIError>('/store/payment', payment);
  if (response.status === 200) {
    return response.data;
  }
  throw new Error(response.data.msg);
}

export async function verifyPayment(
  payment: common.VerifyPayment
): Promise<common.Payment | errors.APIError> {
  const response = await api.post<any, any | errors.APIError>('/store/payment_verify', payment);
  if (response.status === 200) {
    return response.data;
  }
  throw new Error(response.data.msg);
}

export async function createTransfer(
  wallet: string,
  amount: number
): Promise<common.Payment | errors.APIError> {
  const response = await api.post<any, any | errors.APIError>('/store/transfers', {
    amount,
    wallet
  });
  if (response.status === 200) {
    return response.data;
  }
  throw new Error(response.data.msg);
}
