import React, { useEffect, useRef, useState } from 'react';
import { Box, Heading, Page, PageHeader } from 'grommet';
import { Notification } from 'grommet-icons';
import Centrifuge from 'centrifuge';
import toast from 'react-hot-toast';
import { Filter, FiltersProvider } from '@packages/design/components';
import { ActionCard, Activity, HorizontalList } from 'components';
import { allEngage } from 'services/engage';
import { Loaders } from 'app/design/Loaders';
import { MessageListener, setupPush } from '../utils/networking';
import { useAuth } from '../context/auth';

export function Engage() {
  const {
    state: { session, loading }
  } = useAuth();
  const [activity, setActivity] = useState([]);
  const [loadingActivity, setLoadingActivity] = useState(true);
  const centrifuge = useRef<Centrifuge>(null);
  useEffect(() => {
    async function run() {
      setLoadingActivity(true);
      try {
        const result = await allEngage();
        setActivity(result.data);
      } catch (error: any) {
        toast.error(error.message);
      }
      setLoadingActivity(false);
    }
    run();
  }, []);

  const ms = MessageListener((response) => {
    toast(JSON.stringify(response));
  });

  useEffect(() => {
    async function run() {
      try {
        centrifuge.current = await setupPush(ms);
      } catch {}
    }
    run();
    return () => {
      if (centrifuge.current) {
        centrifuge.current.disconnect();
      }
    };
  }, []);

  const [sections, setSections] = useState([
    {
      title: 'Messages',
      subtitle: 'Reach out to your customers about deals, news or just seasonal changes',
      actions: [
        {
          title: 'Send a message',
          subtitle: 'One shot message',
          icon: <Notification />,
          description:
            'Show your customers that you are thinking of them or just send a seasonal message',
          buttonTitle: 'Send SMS',
          enabled: true,
          to: '/tools/sms'
        },
        {
          title: 'Product Deals',
          subtitle: 'Customers save on deals',
          icon: <Notification />,
          description:
            "Got a stock that's expiring or just need to let your customers know of new products",
          buttonTitle: 'Send Deal',
          enabled: false
        }
      ]
    }
  ]);

  if (loading || loadingActivity) return <Loaders.Dashboard />;
  return (
    <Page pad={{ horizontal: 'large' }}>
      {sections.map(({ title, subtitle, actions }) => (
        <>
          <PageHeader title={title} subtitle={subtitle} />
          <HorizontalList>
            {session?.data &&
              actions.map(({ title, subtitle, description, buttonTitle, enabled, to }) => (
                <ActionCard
                  key={title}
                  to={to}
                  user={session.data}
                  buttonTitle={buttonTitle}
                  title={title}
                  subtitle={subtitle}
                  description={description}
                  enabled={enabled}
                />
              ))}
          </HorizontalList>
          <FiltersProvider>
            <Box gap="small" pad={{ vertical: 'medium' }}>
              <Box direction="row" align="center" gap="small">
                <Heading level="2">Recent activity</Heading>
                <Filter
                  data={activity}
                  // filters={filtersConfig}
                  searchFilter={{ placeholder: 'Search' }}
                />
              </Box>
              <Activity />
            </Box>
          </FiltersProvider>
        </>
      ))}
    </Page>
  );
}

Engage.propTypes = {};

export default Engage;
