import { InviteScreen } from 'app/features/member/invite-screen';
import { VerifyCodeScreen } from 'app/features/auth/verifycode-screen';
import { acceptInvitation, getInvitation } from 'services/organization';
import { useLocation } from '@reach/router';
import { parse } from 'query-string';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { getAuth, RecaptchaVerifier } from 'firebase/auth';
import api from 'services/api';
import { useLocalStorage } from 'usehooks-ts';
import { navigate } from 'gatsby';
import app from '../firebase';

function setupRecaptacha(callback: (token: any) => Promise<void>) {
  const auth = getAuth(app);
  window.recaptchaVerifier = new RecaptchaVerifier(
    'join',
    {
      size: 'invisible',
      callback
    },
    auth
  );
  window.recaptchaVerifier.render().then((widgetId) => {
    window.recaptchaWidgetId = widgetId;
  });
}

export function MemberInvite() {
  const location = useLocation();
  const [token, setToken] = useState('');

  useEffect(() => {
    setupRecaptacha(async (token) => {
      setToken(token);
    });
  }, []);

  const [invitation, setInvitation] = useState(null);
  const [inviteOTP, setInviteOTP] = useState(null);
  const searchParams: { token?: string; phone?: string } = parse(location.search);
  const [creatingOtp, setCreatingOtp] = useState(false);
  const [verifying, setVerifying] = useState(false);
  const [phone, setPhone] = useState(null);
  const [session, setSession] = useLocalStorage('vsn', {});
  const [auth, setAuth] = useState(null);

  useEffect(() => {
    const run = async () => {
      // TODO: remove this when server side rendering is fixed/setup
      try {
        const resp = await getInvitation(searchParams.token);
        setInvitation(resp);
      } catch (error) {
        console.log(error);
      }
    };
    run();
  }, [location, searchParams.token]);

  const onJoin = async (data) => {
    if (creatingOtp) return;
    setCreatingOtp(true);
    const phone = data.phone.replace(/\s/g, '').replace('+', '');
    try {
      //   const resp = await createInviteOtp(
      //     data.phone.replace(/\s/g, '').replace('+', ''),
      //     invitation.id,
      //     location.pathname
      //   );
      //   setInviteOTP({ otp: resp, data });
      const resp = await api.get(`/auth/providers/gpa/send/${phone}/${token}`);
      if (resp.status === 200) {
        setInviteOTP({ data: { phone }, resp: resp.data });
      }
    } catch (error) {
      console.error(error);
    }
    setCreatingOtp(false);
  };

  const onChangeInviteForm = (data) => {
    setPhone(data.value.phone);
  };
  const onVerifyOTP = async (code) => {
    if (verifying) return;
    setVerifying(true);
    toast.loading('verifiying code');
    try {
      const resp = await api.get(`/auth/providers/gpa/verify/${inviteOTP.data.phone}/${code}`);
      if (resp?.data?.token) {
        api.setHeader('Authorization', `bearer ${resp.data.token}`);
        // set user info, which has an effect that sends request to organizationmember to update with current user
        // then navigates away to teller app if user is a teller
        setAuth(resp.data);
      } else {
        toast.dismiss();
        toast.error('code is invalid or used');
        setVerifying(false);
      }
    } catch (error) {}

    setVerifying(false);
  };
  const onCancel = () => {
    window.history.back();
  };
  useEffect(() => {
    async function run() {
      toast.dismiss();
      try {
        await toast.promise(acceptInvitation(invitation.id), {
          loading: 'accepting invitation...',
          success: 'done',
          error: (err) => err.msg || err.message || 'could not accept invite'
        });
        setSession(auth);
      } catch (error) {}
    }
    if (auth) {
      run();
    }
  }, [auth]);

  if (inviteOTP) {
    return (
      <VerifyCodeScreen
        phone={inviteOTP.data.phone}
        onsubmit={onVerifyOTP}
        loading={verifying}
        onCancel={onCancel}
      />
    );
  }
  return (
    <InviteScreen
      member={invitation}
      organization={invitation?.organization}
      onJoin={onJoin}
      onChange={onChangeInviteForm}
      loading={creatingOtp}
    />
  );
}

export default MemberInvite;
