import { OtpPinEntry } from 'app/design/OtpPinEntry';
import React from 'react';

export interface VerifyCodeScreenProps {
  phone: string;
  onsubmit;
  onCancel;
  loading: boolean;
}

export function VerifyCodeScreen({ phone, onsubmit, loading, onCancel }: VerifyCodeScreenProps) {
  return (
    <div className="mx-auto flex px-5 pt-10 lg:my-auto">
      <OtpPinEntry phone={phone} onSubmit={onsubmit} loading={loading} onCancel={onCancel} />
    </div>
  );
}
