import User, { IUser } from 'business/models/user';
import { navigate } from 'gatsby';
import api from './api';

export function checkResponseAuth(response): boolean {
  if (response.status === 401) {
    api.deleteHeader('Authorization');
    if (typeof window !== undefined) {
      localStorage.clear();
    }
    navigate('/auth/signin');
    return false;
  }
}

export const me = async (): Promise<IUser> => {
  const response = await api.get<IUser>('/auth/me');
  if (response.status === 200) {
    return Object.assign(new User(), response.data);
  }
  throw new Error(response.data.msg || 'failed');
};
