import * as React from 'react';
import { Grid, Box, Heading, Paragraph, Form, TextInput } from 'grommet';
import { useQueryParam, StringParam } from 'use-query-params';
import { useLocalStorage } from 'usehooks-ts';
import { navigate } from 'gatsby';
import useDigitInput from '../../hooks/useDigitInput';
import api from '../../services/api';
import Layout from '../../components/Layout';

export default function Home(props) {
  const [session, setSession] = useLocalStorage('vsn', {});
  const [phone] = useQueryParam('phone', StringParam);
  const [loading, setLoading] = React.useState(false);
  const [code, setCode] = React.useState('');
  const ref = React.useRef(null);
  const digits = useDigitInput({
    acceptedCharacters: /^[0-9]$/,
    length: 6,
    value: code,
    onChange: (v) => {
      setCode(v.trim());
    }
  });
  React.useEffect(() => {
    if (!loading && phone && code.length === 6) {
      sendCode(code);
    }
  }, [loading, phone, sendCode, code]);

  async function sendCode(code) {
    setLoading(true);
    const resp = await api.get(`/auth/providers/gpa/verify/${phone}/${code}`);
    if (resp.status === 200) {
      api.setHeader('Authorization', `bearer ${resp.data.token}`);
      setSession(resp.data);
      navigate('/');
    }
    setCode('');
    setLoading(false);
    ref?.current?.focus();
  }

  return (
    <Box>
      <Grid fill="horizontal" width="100%" pad="large" columns="flex">
        <Box align="stretch" justify="center" pad={{ vertical: 'large' }} direction="column">
          <Box
            align="start"
            justify="start"
            pad={{ bottom: 'small', left: 'small' }}
            direction="column">
            <Heading>Almost there!</Heading>
            <Paragraph size="xlarge">Please enter the code you received on your phone</Paragraph>
          </Box>
          <Form>
            <Box direction="row" gap="small">
              {digits.map((digit, k) => (
                <TextInput
                  key={`${k}`}
                  width="100%"
                  inputMode="decimal"
                  autoFocus={!loading && k === 0}
                  disabled={loading}
                  {...digit}
                  {...(k === -1 ? { ref } : {})}
                />
              ))}
            </Box>
          </Form>
        </Box>
      </Grid>
    </Box>
  );
}
