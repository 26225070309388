import { Box, Text } from 'grommet';
import { LinkDown, LinkUp, Up, Visa } from 'grommet-icons';
import { DateTime } from 'luxon';
import React from 'react';
import tw from 'twin.macro';
// import { Visa } from "react-pay-icons";

const CardContainer = tw(Box)`py-4 px-4 max-w-xl`;

export interface TransactionTileProps {
  session?;
  transaction: common.Transaction;
}

export function CardPayment({
  channel,
  authorization: { card_type, last4, bank, exp_month, exp_year }
}) {
  if (card_type.length === 0) {
    return (
      <CardContainer
        border={{ color: 'light-3' }}
        round="small"
        margin={{ vertical: 'small' }}
        gap="small"
        background="brand">
        <Box direction="row" gap="small" align="center">
          <Box>
            <Text>{bank}</Text>
            <Box gap="xsmall">
              <Text weight="bold" color="text-strong">
                {card_type} **** {last4}
              </Text>
              {/* <Text size="small">Expires on {exp_month}/{exp_year}</Text> */}
            </Box>
          </Box>
        </Box>
      </CardContainer>
    );
  }
  let CardIcon;
  const ct = card_type as string;
  if (ct.includes('visa')) {
    CardIcon = Visa;
  } else {
    CardIcon = Box;
  }
  return (
    <CardContainer
      border={{ color: 'light-3' }}
      round="small"
      margin={{ vertical: 'small' }}
      gap="small"
      background="brand">
      <Box direction="row" gap="small" align="center">
        <CardIcon style={{ margin: 10, width: 50 }} />
        <Box>
          <Text>{bank}</Text>
          <Box gap="xsmall">
            <Text weight="bold" color="text-strong">
              {card_type} **** {last4}
            </Text>
            <Text size="small">
              Expires on
              {exp_month}/{exp_year}
            </Text>
          </Box>
        </Box>
      </Box>
    </CardContainer>
  );
}

export function TransactionTile({ session, transaction }: TransactionTileProps) {
  let initiator;
  let recipient;
  let message;
  let fromMessage;
  const { mode } = transaction;
  const send = !(session.data.id === transaction.initiator.id);
  const wallet = transaction.wallet?.title || 'unknown';
  const currency = transaction.wallet?.currency || 'NGN';
  const amount = transaction.amount / 100;
  const date = transaction.created_at;
  if (mode === 'credit') {
    initiator = '';
    recipient = 'You';
    message = `You added ${amount} credits`;
    fromMessage = transaction.payment?.authorization ? (
      <CardPayment
        authorization={transaction.payment.authorization}
        channel={transaction.payment.channel}
      />
    ) : (
      <Text />
    );
  } else {
    initiator =
      session.data.id === transaction.recipient.id
        ? 'You'
        : transaction.recipient.verified_phone ||
          transaction.recipient.firstName ||
          transaction.recipient.name;
    recipient =
      session.data.id === transaction.initiator.id
        ? 'You'
        : transaction.initiator.verified_phone ||
          transaction.initiator.firstName ||
          transaction.initiator.name;
    message = `${send ? 'You' : recipient} ${send ? 'sent' : 'received'} ${amount} credits`;
    fromMessage = <Text>{send ? 'to ' : 'from '}</Text>;
  }
  return (
    <Box border={{ side: 'bottom', color: 'light-1' }} pad={{ bottom: 'medium' }}>
      <Box direction="row" gap="medium" justify="between">
        <Box direction="row-responsive" justify="between" fill="horizontal">
          <Box direction="row" gap="small">
            <Box
              round="small"
              background={send ? 'accent-1' : 'brand'}
              width="33px"
              height="48px"
              align="center"
              justify="center">
              {send ? <LinkUp size="18" /> : <LinkDown size="18" />}
            </Box>
            <Box>
              <Text weight="bold" color="dark-2">
                {message}
              </Text>
              <Text color="dark-4" margin={{ bottom: 'small' }}>
                {fromMessage}
                {send ? recipient : initiator}
              </Text>
            </Box>
          </Box>
          <Box direction="row" margin={{ left: 'large', bottom: 'medium' }}>
            {/* <Box direction="row" gap="5px">
              <Text size="small">{DateTime.fromISO(date).toFormat(
                "dd, MMM",
              )}</Text>
              <Text size="small">{DateTime.fromISO(date).toFormat(
                "yyyy",
              )}</Text>
            </Box> */}

            <Text size="small">{DateTime.fromISO(date).toFormat('dd, MMM yyyy')}</Text>
            <Text size="small" color="dark-2">
              {' '}
              -{DateTime.fromISO(date).toFormat('hh:mm a')}
            </Text>
          </Box>
        </Box>
      </Box>
      <Box margin={{ left: 'large' }}>
        <Text color="dark-2" weight="bold">
          {wallet} ● Wallet
        </Text>
        <Text color="dark-2" size="small">{`Value ● ${currency} ${amount}`}</Text>
      </Box>
    </Box>
  );
}
