import { getInvitation } from 'services/organization';
import MemberInvite from 'logical/MemberInvite';

// export const getServerData = async (context: {
//   headers;
//   method: string;
//   url: string;
//   query: { inv: string };
//   params;
// }) => {
//   const unsplit = context.url.split('?', -1);
//   if (unsplit[1].endsWith('member/invite')) {
//     try {
//       const invitation = await getInvitation(context.query.inv);
//       return { invitation };
//     } catch (error) {
//       return {};
//     }
//   }
//   return {};
// };
export default function Invite() {
  return <MemberInvite />;
}
