import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Grommet, ResponsiveContext } from 'grommet';
import { FiltersLayer, useFilters } from '.';
import { FiltersPanel } from './FiltersPanel';
import theme from '../theme';
import { FiltersModal } from './FiltersModal';

export interface FilterProps {}
export function Filter({
  actions,
  configure,
  type,
  filters,
  layerProps,
  primaryKey,
  onFilterChange,
  onApplyFilter,
  data
}) {
  const {
    setData,
    setFilterAttributes,
    setLayerProps,
    setPrimaryKey,
    syncFilteredResults,
    filters: selectedFilters
  } = useFilters();
  const size = useContext(ResponsiveContext);

  useEffect(() => {
    setData(data);
  }, [data, setData]);

  useEffect(() => {
    setFilterAttributes(filters);
  }, [filters, setFilterAttributes]);

  useEffect(() => {
    setLayerProps(layerProps);
  }, [layerProps, setLayerProps]);

  useEffect(() => {
    setPrimaryKey(primaryKey);
  }, [primaryKey, setPrimaryKey]);

  /* keep filteredResults up to date as parent data set may change */
  useEffect(() => {
    syncFilteredResults();
  }, [syncFilteredResults]);

  useEffect(() => {
    onFilterChange(selectedFilters);
  }, [onFilterChange, selectedFilters]);

  return <Grommet theme={theme}>{type === 'panel' ? <FiltersPanel /> : <FiltersModal />}</Grommet>;
}

Filter.propTypes = {
  actions: PropTypes.element,
  configure: PropTypes.element,
  data: PropTypes.array,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      property: PropTypes.string,
      label: PropTypes.string,
      filterType: PropTypes.oneOf(['CheckBoxGroup', 'RangeSelector']),
      filterOptions: PropTypes.array,
      inputProps: PropTypes.object
    })
  ),
  layerProps: PropTypes.shape({
    // object of Grommet Box props
    containerProps: PropTypes.object,
    // object of Grommet Box props
    contentProps: PropTypes.object
    // Grommet Layer props
  }),
  primaryKey: PropTypes.string,
  searchFilter: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      placeholder: PropTypes.string
    })
  ])
};

Filter.defaultProps = {
  onFilterChange: () => {}
};
