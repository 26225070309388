import api from './api';

export async function allCustomers(skip: number): Promise<common.Customer[] | errors.APIError> {
  const response = await api.get<any, any | errors.APIError>('/store/customer', {
    skip: skip || 0,
    sort: { modified_at: 'desc' }
  });
  if (response.status === 200) {
    return response.data;
  }
  throw new Error(response.data?.msg);
}

export async function getCustomerByPhone(
  phone: string
): Promise<common.Customer[] | errors.APIError> {
  const response = await api.get<any, any | errors.APIError>('/store/user', {
    q: { verified_phone: phone }
  });
  if (response.status === 200) {
    return response.data;
  }
  throw new Error(response.data?.msg);
}
