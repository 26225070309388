import { Member } from 'business/models/member';
import { Organization } from 'business/models/organization';
import api from './api';

export async function createOrganization(
  organization: common.Organization
): Promise<common.Organization | errors.APIError> {
  const response = await api.post<any, any | errors.APIError>('/store/organization', organization);
  if (response.status === 200) {
    return Object.assign(new Organization(), response.data);
  }
  throw new Error(response.data.msg);
}

export async function updateOrganization(
  id: string,
  organization: common.Organization
): Promise<common.Organization | errors.APIError> {
  const response = await api.put<any, any | errors.APIError>(
    `/store/organization/${id}`,
    organization
  );
  if (response.status === 200) {
    return Object.assign(new Organization(), response.data);
  }
  throw new Error(response.data.msg);
}

export async function addMember(member: common.Member): Promise<common.Member | errors.APIError> {
  const response = await api.post<common.Member, any | errors.APIError>(
    '/store/organizationmember ',
    member
  );
  if (response.status === 200) {
    return response.data;
  }
  throw new Error(response.data.msg);
}

export async function removeMember(
  member: common.Member
): Promise<common.Member[] | errors.APIError> {
  const response = await api.delete<any | errors.APIError>(
    `/store/organizationmember/${member?.id}`
  );
  if (response.status === 200) {
    return response.data;
  }
  throw new Error(response.data.msg);
}
export async function allMembers(): Promise<common.Member[] | errors.APIError> {
  const response = await api.get<common.Member, any | errors.APIError>(
    '/store/organizationmember '
  );
  if (response.status === 200) {
    return response.data;
  }
  throw new Error(response.data.msg);
}
export async function getInvitation(id: string): Promise<common.Member[] | errors.APIError> {
  const response = await api.get<common.Member, any | errors.APIError>(
    `/store/organizationmember/${id}?token=${id}`
  );
  if (response.status === 200) {
    return Object.assign(new Member(), response.data);
  }
  throw new Error(response.data.msg);
}

export async function acceptInvitation(id: string): Promise<common.Member[] | errors.APIError> {
  const response = await api.put<common.Member, any | errors.APIError>(
    `/store/organizationmember/${id}?token=${id}`,
    {}
  );
  if (response.status === 200) {
    return response.data;
  }
  throw new Error(response.data.msg);
}
