import React from 'react';
import { ThemeContext } from 'grommet';

export function AutoBoxMinH({ children }) {
  return (
    <ThemeContext.Extend
      value={{
        box: { extend: () => 'min-height:auto' },
      }}
    >
      {children}
    </ThemeContext.Extend>
  );
}

AutoBoxMinH.propTypes = {};

export default AutoBoxMinH;
