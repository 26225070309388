import { INamespace } from 'business/models/namespace';
import api from './api';

export async function createNamespace(namespace: string): Promise<INamespace | errors.APIError> {
  const response = await api.post<any, INamespace | errors.APIError>('/store/namespace', {
    name: namespace
  });
  if (response.status === 200) {
    return response.data as INamespace;
  }
  throw new Error(response.data.msg);
}

export async function editNamespace(
  id: string,
  namespace: string
): Promise<INamespace | errors.APIError> {
  const response = await api.put<any, INamespace | errors.APIError>(`/store/namespace/${id}`, {
    name: namespace
  });
  if (response.status === 200) {
    return response.data as INamespace;
  }
  throw new Error(response.data.msg);
}
