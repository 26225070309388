import { Box } from 'grommet/components/Box';
import { Heading } from 'grommet/components/Heading';
import { Text } from 'grommet/components/Text';
import React, { useState } from 'react';
import { LottieOptions, useLottie } from 'lottie-react';
import { Button, Spinner } from 'grommet';
import phoneAnimation from '../../animations/102231-enter-otp.json';

export interface OtpFlowProps {
  phone: string;
  step?: number;
  form: any; // this had to be done because the input value was not changed
  onCancel?;
}

const options: LottieOptions = {
  animationData: phoneAnimation,
  loop: true
};

export function OtpFlow({ phone, step, form, onCancel }: OtpFlowProps) {
  const [loading, setLoading] = useState(false);

  const { View: PhoneAnimation } = useLottie(options, {
    width: '100%',
    height: '50%',
    padding: 0,
    margin: 0
  });
  const currentStyle = { weight: 'bold', size: 'large', color: 'primary' };
  const normalStyle = { weight: 'normal', size: 'medium', color: 'light-6' };
  return (
    <Box pad="medium">
      <Box direction="row-responsive" gap="medium">
        <Box width="small">{PhoneAnimation}</Box>
        <Box animation="fadeIn" gap="medium" pad={{ horizontal: 'large' }}>
          <Box direction="row" align="center" gap="small">
            <Box gap="small">
              <Heading level={1} margin={{ bottom: '0' }}>
                New Customer
              </Heading>
              <Text size="large">{phone}</Text>
            </Box>
            {loading && <Spinner />}
          </Box>
          {/* <Text size="small" color="dark-3">Customer needs to be registered before you can send them credit.</Text> */}
          {/* {step > 0
            && (
            <Text {...(step === 1 ? currentStyle : normalStyle)}>
              Sent OTP verification code ...
            </Text>
            )} */}
          {step === 2 && (
            <Box animation="fadeIn" gap="medium">
              <Text {...currentStyle}>Wait for customer to verify phone number...</Text>
              {form}
              <Button label="Cancel" alignSelf="start" onClick={onCancel} />
            </Box>
          )}
          {step > 2 && (
            <Box animation="fadeIn" gap="medium">
              <Text {...(step === 3 ? currentStyle : normalStyle)}>
                Phone number verified. You can now send credit to the customer...
              </Text>
              <Box direction="row" gap="medium">
                <Text>Taking you back to send credit ...</Text>
                <Spinner />
              </Box>
              {form}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}

OtpFlow.defaultProps = { step: 1 };
