import React from 'react';
import { LogoIcon } from '../LogoIcon';

export interface ProfileFormProps {
  register;
  loading: boolean;
  avatar: string;
}

export function ProfileForm({ register, avatar }: ProfileFormProps) {
  return (
    <div>
      <div className="md:gap-6">
        <div className="grid grid-cols-6 gap-4">
          <div className="col-span-6">
            {/* <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">Profile</h3>
              <p className="mt-1 text-sm text-gray-600">
                This information will be displayed publicly so be careful what you share.
              </p>
            </div> */}
          </div>
          <div className="col-span-6 md:mt-0">
            <div>
              <div className="sm:overflow-hidden sm:rounded-md">
                <div className="space-y-6 bg-white py-2 sm:p-6">
                  <div>
                    <label className="label block font-medium text-gray-700" htmlFor="file-upload">
                      Avatar
                      <div className="mt-1 flex items-center gap-2">
                        <span className="inline-block h-12 w-12 overflow-hidden rounded-full bg-gray-100">
                          {avatar ? (
                            <div className="avatar">
                              <div className="rounded-full border-gray-300">
                                <img src={avatar} alt="avatar" className="object-contain" />
                              </div>
                            </div>
                          ) : (
                            <svg
                              className="h-full w-full text-gray-300"
                              fill="currentColor"
                              viewBox="0 0 24 24">
                              <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                            </svg>
                          )}
                        </span>
                        <input
                          {...register('avatar')}
                          accept=".jpg,.jpeg,.png"
                          type="file"
                          className="file-input file-input-bordered file-input-primary w-full max-w-xs"
                        />
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
