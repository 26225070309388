import { IBank } from 'business/models';
import api from './api';
import { APIError } from './api.types';

export async function deleteAccount(account): Promise<object | APIError> {
  const response = await api.delete<any, object | APIError>(
    `/store/useraccounts/${account.id}?sort={"created_at":"asc"}`,
    {
      default: true
    }
  );
  if (response.status === 200) {
    return response.data as object;
  }
  throw new Error(response.data.msg);
}
export async function defaultAccount(account): Promise<object | APIError> {
  const response = await api.put<any, object | APIError>(`/store/useraccounts/${account.id}`, {
    default: true
  });
  if (response.status === 200) {
    return response.data as object;
  }
  throw new Error(response.data.msg);
}
export async function createAccount(account): Promise<object | APIError> {
  const response = await api.post<any, object | APIError>('/store/useraccounts', account);
  if (response.status === 200) {
    return response.data as object;
  }
  throw new Error(response.data.msg);
}

export async function allAccounts(): Promise<[] | APIError> {
  const response = await api.get<any, object | APIError>(
    '/store/useraccounts?sort={"default": "desc", "created_at": "asc"}'
  );
  if (response.status === 200) {
    return response.data.data;
  }
  throw new Error(response.data.msg);
}

export async function fetchBanks(location?: string): Promise<IBank[] | APIError> {
  const response = await api.get<any, object | APIError>(
    '/store/bank?size=100&sort={"name":"asc"}'
  );
  if (response.status === 200) {
    return response.data.data as Bank[];
  }
  throw new Error(response.data.msg);
}
