import { create } from 'apisauce';
import { AxiosInstance } from 'axios';
import axiosRetry from 'axios-retry';
import { navigate } from 'gatsby';

const api = create({
  baseURL: process.env.GATSBY_DOSTOW_API,
  timeout: 10000,
  headers: {
    'x-dostow-group-access-key': process.env.GATSBY_DOSTOW_KEY,
    Accept: 'application/json'
  }
});

axiosRetry(api.axiosInstance as AxiosInstance, { retries: 3 });

api.axiosInstance.interceptors.request.use(
  (req) => {
    let vsn: { token?: string } = {};
    if (typeof window !== 'undefined') {
      const v = localStorage.getItem('vsn');
      if (v) {
        vsn = JSON.parse(v);
      }
    }
    req.headers = {
      ...req.headers,
      ...(vsn?.token ? { Authorization: `Bearer ${vsn.token}` } : {})
    };
    return req;
  },
  (error) => Promise.reject(error)
);
api.axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response?.data?.error?.fields) {
      error.response.data.msg = error.response.data.error.fields[0].description;
    }
    if (
      error.response?.status === 401 &&
      error.response.data.msg === 'user session is active on another device'
    ) {
      navigate('/auth/logout');
    } else if (error.config.url.includes('auth/me')) {
      navigate('/auth/logout');
    }
    return error;
  }
);
export default api;
