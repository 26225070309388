import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from 'app/design/Button';
import { FileInput } from 'app/design/FileInput';

export interface EditBusinessProps {
  defaultValues;
  onUpload;
  busy;
  onSubmitDetailForm;
}

export function FormGroupHeader({ name, description }) {
  return (
    <div className="mb-5 md:col-span-1">
      <div className="">
        <h3 className="text-lg font-medium leading-6 text-gray-900">{name}</h3>
        <p className="mt-1 text-sm text-gray-600">{description}</p>
      </div>
    </div>
  );
}

export function DetailForm({ onUpload, businessTypes, banner, busy, register }) {
  const fields = [
    { name: 'name', type: 'text', label: 'Business Name', required: true },
    { name: 'email', type: 'email', label: 'Business Email', required: true },
    { name: 'phone', type: 'phone', label: 'Business Phone', required: true },
    { name: 'type', type: 'select', options: businessTypes, label: 'Business Type', required: true }
  ];
  return (
    <div className="flex flex-col gap-10">
      <div className="flex flex-col justify-center">
        <FormGroupHeader name="Banner" description="Upload a banner for your business" />
        <FileInput
          // {...register('banner')}
          value={banner}
          onChange={(e) => {
            // setNumFiles(e.target.files?.length);
            onUpload(e.target.files);
          }}
        />
      </div>
      <div className="flex-row gap-3">
        <div className="md:col-span-1">
          <div className="">
            <h3 className="text-lg font-medium leading-6 text-gray-900">Detail Information</h3>
            <p className="mt-1 text-sm text-gray-600">
              Information here will be used to identify your business.
            </p>
          </div>
        </div>
        <div className="grid gap-6 md:grid-cols-2">
          {fields.map(({ name, type, label, options, ...rest }) => {
            if (type === 'select') {
              return (
                <div key={name} className="form-control">
                  <label className="label" htmlFor={name}>
                    <span className="label-text">{label}</span>
                  </label>
                  <select className="select select-bordered" {...register(name)} required>
                    <option disabled>Select a business type</option>
                    {options.map((t) => (
                      <option key={t}>{t}</option>
                    ))}
                  </select>
                </div>
              );
            }
            return (
              <div key={name} className="form-control">
                <label htmlFor={name} className="label">
                  <span className="label-text">{label}</span>
                </label>
                <input
                  required
                  {...register(name)}
                  {...rest}
                  type={type}
                  className="input input-bordered w-full"
                />
              </div>
            );
          })}
        </div>
      </div>
      <div>
        <div className="form-control">
          <label className="label" htmlFor="description">
            <span className="label-text">Describe your business</span>
          </label>
          <textarea
            className="textarea textarea-bordered h-24 w-full"
            placeholder="Description"
            {...register('description')}
          />
        </div>
      </div>
      <div>
        <div className="md:col-span-1">
          <div className="">
            <h3 className="text-lg font-medium leading-6 text-gray-900">Business location</h3>
            <p className="mt-1 text-sm text-gray-600">
              Use a permanent address where you can receive mail.
            </p>
          </div>
        </div>
        <div className="grid gap-6 md:grid-cols-2">
          <div className="col-span-6 sm:col-span-3">
            <label htmlFor="country" className="label">
              Country
            </label>
            <select
              id="country"
              name="country"
              required
              autoComplete="country-name"
              className="select  select-bordered mt-1 w-full"
              {...register('country')}>
              <option>Nigeria</option>
              <option>Ghana</option>
              <option>Tanzania</option>
            </select>
          </div>
          <div className="col-span-6">
            <label htmlFor="address" className="label">
              Street address
            </label>
            <input
              type="text"
              name="address"
              id="address"
              {...register('address')}
              required
              autoComplete="street-address"
              className="input input-bordered mt-1 w-full"
            />
          </div>

          <div className="col-span-6 sm:col-span-6 lg:col-span-2">
            <label htmlFor="city" className="label">
              City
            </label>
            <input
              type="text"
              name="city"
              id="city"
              required
              autoComplete="address-level2"
              className="input input-bordered mt-1 w-full"
              {...register('city')}
            />
          </div>
          <div className="col-span-6 sm:col-span-3 lg:col-span-2">
            <label htmlFor="region" className="label">
              State / Province
            </label>
            <input
              type="text"
              name="region"
              id="region"
              required
              autoComplete="address-level1"
              className="input input-bordered mt-1 block w-full"
              {...register('state')}
            />
          </div>
          <div className="col-span-6 sm:col-span-3 lg:col-span-2">
            <label htmlFor="postal-code" className="label">
              ZIP / Postal code
            </label>
            <input
              type="text"
              name="postal-code"
              id="postal-code"
              autoComplete="postal-code"
              className="input input-bordered mt-1 block w-full"
              {...register('postalCode')}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

DetailForm.defaultProps = {
  fields: []
};

export function EditBusiness({
  defaultValues,
  onUpload,
  busy,
  onSubmitDetailForm
}: EditBusinessProps) {
  const businessTypes = ['Supermarket', 'Barbing Salon', 'Salon', 'Eatery', 'Restaurant', 'Other'];
  const detailForm = useForm({ defaultValues });
  useMemo(() => {
    detailForm.reset(defaultValues);
  }, []);

  return (
    <div className="w-full px-5 md:px-14">
      <div className="prose py-5">
        <h2 className="m-0">Edit your business</h2>
        <span>Tell us about your business</span>
      </div>
      <form onSubmit={detailForm.handleSubmit(onSubmitDetailForm)}>
        <DetailForm
          onUpload={onUpload}
          banner={defaultValues?.banner_file}
          businessTypes={businessTypes}
          register={detailForm.register}
          busy={busy}
          errors={detailForm.formState.errors}
        />
        <div className="hidden sm:block" aria-hidden="true">
          <div className="py-5">
            <div className="border-t border-gray-200" />
          </div>
        </div>

        <div className="flex justify-between bg-gray-50 px-4 py-3 sm:px-6">
          <div />
          <Button
            disabled={busy}
            type="submit"
            className="btn btn-primary"
            label="Save"
            loading={busy}
          />
        </div>
      </form>
    </div>
  );
}
