import React, { useEffect, useRef, useState } from 'react';

import { object, number } from 'superstruct';
import PinField from 'react-pin-field';
import styled from 'styled-components';
import tw from 'twin.macro';
import useCountdown from 'app/hooks/useCountdown';
import { LogoIcon } from '../LogoIcon';
import { Button } from '../Button';

const schema = object({
  pin: number()
});
const StyledPinField = styled(PinField)`
  & {
    ${tw`border rounded-lg border-blue-200 bg-base-100 appearance-none outline-none mr-4 shadow-sm h-8 w-8 lg:h-14 lg:w-14 sm:w-8 sm:h-8`}
    font-size: 2rem;
    text-align: center;
    transition-duration: 250ms;
    transition-property: color, border, box-shadow, transform;

    &:last-of-type {
      ${tw`m-0`}
    }

    &:focus {
      ${tw`border-blue-500 shadow-sm`}
    }

    &:invalid {
      animation: shake 5 linear 75ms;
      ${tw`border-red-500 shadow-sm`}

      & + .pin-entry {
        ${tw`border-l-red-500`}
      }
    }

    &.complete {
      ${tw`border-green-600 text-green-600`}

      &[disabled] {
        cursor: not-allowed;
        opacity: 0.5;
      }

      & + .pin-entry {
        ${tw`border-l-green-600`}
      }
    }
  }

  @keyframes shake {
    from {
      transform: translateY(-5%);
    }
    to {
      transform: translateY(5%);
    }
  }
`;

export function OtpPinEntry({ onSubmit, phone, loading, onCancel }: OtpPinEntryProps) {
  const [targetDate, setTargetDate] = useState(5 * 60 * 1000 + new Date().getTime());
  const [days, hours, minutes, seconds] = useCountdown(targetDate);
  const pinRef = useRef(null);
  const [done, setDone] = useState(false);
  useEffect(() => {
    setDone(!loading);
  }, [loading]);
  useEffect(() => {
    if (!loading && done) {
      pinRef.current.forEach((input, i) => (pinRef.current[i].value = ''));
      pinRef.current[0].focus();
    }
  }, [done, loading]);

  return (
    <div className="card bg-white shadow-xl">
      <div className="card-header px-4 pl-7">
        <div className="navbar bg-base-100">
          {/* <div className="mr-4 flex-none">
            <LogoIcon />
          </div> */}
          <div className="flex-1">
            <h3 className="text-xl normal-case">Verify your phone number</h3>
          </div>
          <div className="flex-none" />
        </div>
      </div>
      <div className="card-body self-center">
        <div className="textcenter mb-2 flex flex-col">
          <h4>Enter the 6 digit code that was sent to your number</h4>
          <span>{phone}</span>
        </div>
        <div className="self-center">
          <StyledPinField
            ref={pinRef}
            disabled={loading}
            className="pin-entry"
            validate="0123456789"
            length={6}
            inputMode="numeric"
            onComplete={onSubmit}
            autoFocus
          />
        </div>
        <div className="h-[40px]">
          {days + hours + minutes + seconds > 0 && (
            <div className="mb-4 flex flex-row items-center justify-center gap-2">
              <span className="text-sm">resend in </span>
              <div className="flex items-center justify-center gap-1">
                <div className="text-sm leading-[0.875rem]">
                  <span className="countdown text-sm leading-[0.875rem]">
                    <span style={{ '--value': minutes }} />
                  </span>
                  min
                </div>
                <div className="text-sm leading-[0.875rem]">
                  <span className="countdown text-sm leading-[0.875rem]">
                    <span style={{ '--value': seconds }} />
                  </span>
                  sec
                </div>
              </div>
            </div>
          )}
        </div>
        <Button
          loading={loading}
          type="button"
          disabled={days + hours + minutes + seconds > 0}
          label="Resend code"
        />
        <a onClick={onCancel} href="#/" className="self-center">
          cancel
        </a>
      </div>
    </div>
  );
}

OtpPinEntry.defaultProps = {
  phone: '+00000000'
};

export interface OtpPinEntryProps {
  onSubmit;
  onCancel;
  phone: string;
  loading: boolean;
}
