import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { superstructResolver } from '@hookform/resolvers/superstruct';
import { object } from 'superstruct';
import { Organization } from 'business/models/organization';
import { Member } from 'business/models/member';
import ImageAvatar from './ImageAvatar';
import { Button } from '../Button';
import { PhoneInput, PhoneValidator } from '../PhoneInput';
import { LogoIcon } from '../LogoIcon';

const schema = object({
  phone: PhoneValidator
});
export function UnauthenticatedModalInvite({
  member,
  loading,
  onChange,
  onSubmit
}: UnauthenticatedModalInviteProps) {
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors }
  } = useForm({
    resolver: superstructResolver(schema)
  });
  useEffect(() => {
    const subscription = watch((value, { name, type, ...all }) =>
      onChange({ value, name, type, all })
    );
    return () => subscription.unsubscribe();
  }, [watch, onChange]);

  return (
    <div className="card mx-auto bg-white shadow-xl lg:w-8/12">
      <div className="card-body">
        <div className="flex flex-col items-center justify-center gap-4 lg:px-10">
          <ImageAvatar organizationAvatar={member?.org?.banner_url} />
          {member && (
            <div className="gap-2">
              <p className="m-0 text-center text-[17px] font-bold leading-[26px] text-gray-800 lg:px-10">
                Hi {member.name}, you have been invited to join {member?.org?.name}
              </p>
              <p className="text-center">Enter your phone to join</p>
              {/* <p className="text-center text-[15px] font-normal leading-[21.5px] text-gray-500">
            Invited by {organization?.owner_user.name}
          </p> */}
            </div>
          )}
          <form className="flex flex-col gap-4">
            <PhoneInput
              placeholder="Enter your phone number to join"
              name="phone"
              rules={{ required: 'phone number is required' }}
              control={control}
              aria-invalid={errors.phone ? 'true' : 'false'}
            />
            {errors.phone && (
              <span className="text-warning text-center text-base">{errors.phone.message}</span>
            )}
            <Button id="join" label="Join" loading={loading} onClick={handleSubmit(onSubmit)} />
          </form>
          <div className="flex items-center py-4 text-center font-normal leading-[normal] text-black">
            <p className="m-0 mx-auto w-10/12 text-[11px]">
              By clicking “Join” above, you acknowledge that you have read and understood, and agree
              to changemida&apos;s Terms & Conditions and Privacy policy.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export interface UnauthenticatedModalInviteProps {
  member: Member;
  loading: boolean;
  onChange;
  onSubmit?: (data) => {};
}

UnauthenticatedModalInvite.defaultProps = {
  onSubmit: (data) => {}
};
export default UnauthenticatedModalInvite;
