import React from 'react';
import { useForm } from 'react-hook-form';
import { IAccount, IBank } from 'business/models';
import { Input } from '../../design/Input';
import { Button } from '../../design/Button';

interface AccountScreenProps {
  accounts?: IAccount[];
  banks?: IBank[];
  onNewAccount;
  busy;
  onSetDefault;
  onRemove;
}

export function AccountScreen({
  onNewAccount,
  accounts,
  busy,
  banks,
  onSetDefault,
  onRemove
}: AccountScreenProps) {
  const {
    register,
    formState: { errors },
    handleSubmit
  } = useForm<{ accountNumer: string; bankCode: string }>({ defaultValues: {} });

  return (
    <div className="w-full space-y-10 ">
      <div className="prose">
        <h2>New account</h2>
      </div>
      <form onSubmit={handleSubmit(onNewAccount)} className="max-w-md space-y-4 ">
        <Input
          error={errors?.accountNumber}
          placeholder="xxxx xxxx xxxx"
          {...register('accountNumber', {
            required: 'Please enter your account number',
            pattern: {
              value: /^[0-9]+$/i,
              message: 'Invalid account numer'
            }
          })}
          label="Account Number"
        />
        <div className="form-control w-full">
          <label className="label" htmlFor="bankCode">
            <span className="label-text">Bank</span>
          </label>
          <select
            {...register('bankCode', {
              required: 'Please choose your bank'
            })}
            placeholder="Choose your bank"
            defaultValue=""
            className="select select-bordered w-full">
            <option disabled value="">
              Choose your bank
            </option>
            {banks?.map((bank) => (
              <option key={bank.id} value={bank.code}>
                {bank.name}
              </option>
            ))}
          </select>
          <label className="label">
            {errors?.bankCode && (
              <span className="label-text-alt text-error">{errors?.bankCode?.message}</span>
            )}
          </label>
        </div>
        <div className="mt-5">
          <Button loading={busy} type="submit">
            Add new account
          </Button>
        </div>
      </form>
      <div className=" w-full overflow-x-auto">
        <div className="prose">
          <h2>Accounts</h2>
        </div>
        <table className="table w-full">
          {/* head */}
          <thead>
            <tr>
              <th className="w-8">Default</th>
              <th>Bank Name</th>
              <th>Account Number</th>
              <th>Account Name</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {accounts?.map((account) => {
              const isActive = account.status === 'done' && account.pull?.data?.active;
              const status =
                account.status === 'done'
                  ? account.pull?.data?.active == true
                    ? 'DONE'
                    : 'PENDING'
                  : account.status;
              return (
                <tr key={account.id}>
                  <th>
                    {isActive ? (
                      <div className="form-control">
                        <label className="label cursor-pointer">
                          <input
                            disabled={busy || account?.default === 1}
                            type="checkbox"
                            className="checkbox"
                            onChange={() => onSetDefault(account)}
                            checked={account?.default === 1}
                          />
                        </label>
                      </div>
                    ) : (
                      <span className="badge-warning badge badge-lg break-words uppercase">
                        {status}
                      </span>
                    )}
                  </th>
                  <td>
                    <div className="flex h-full items-center space-x-3">
                      <div className="avatar">
                        <div className="mask mask-square h-12 w-12">
                          {account.bank?.logo?.url ? (
                            <img src={account.bank?.logo?.url} alt={account.bank?.name} />
                          ) : (
                            <div className="bg-base-200 avatar h-12 w-12 rounded-full" />
                          )}
                        </div>
                      </div>
                      <div>
                        <div className="font-bold">
                          {account.pull?.data?.details?.bank_name || account.bank?.name}
                        </div>
                        <div className="text-sm opacity-50">
                          {account.pull?.data?.details?.bank_code || account.bank?.code}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>{account.pull?.data?.details?.account_number || account.accountNumber}</td>
                  <td>
                    {account.pull?.data?.details?.account_name} <br /> {account.pull?.data?.email}
                  </td>
                  <th>
                    <button
                      type="button"
                      className="btn btn-ghost btn-xs"
                      onClick={() => onRemove(account)}>
                      Remove
                    </button>
                  </th>
                </tr>
              );
            })}
          </tbody>
          {/* foot */}
          <tfoot>
            <tr>
              <th />
              <th>Bank Name</th>
              <th>Account Number</th>
              <th>Account Name</th>
              <th />
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
}

AccountScreen.defaultProps = {
  accounts: [],
  banks: []
};
