/* eslint-disable camelcase */
import { IWallet } from 'business/models/wallet';
import api from './api';

export async function searchWallets(title?: string): Promise<{ data: IWallet[] }> {
  const url = title ? "/store/wallet?s=''" : '/store/wallet';
  const response = await api.get<{ data: IWallet[] }>(url);
  if (response.status === 200) {
    return response.data;
  }
  throw new Error(response.data?.msg || 'failed');
}

export async function organizationWallet(id: string, organization?: string): Promise<IWallet> {
  const url = organization ? `/store/wallet/${id}?org=${organization}` : `/store/wallet/${id}`;
  const response = await api.get<IWallet>(url);
  if (response.status === 200) {
    return response.data;
  }
  throw new Error(response.data?.msg || 'failed');
}

export async function organizationWallets(organization?: string): Promise<{ data: IWallet[] }> {
  const url = organization ? `/store/wallet?org=${organization}` : '/store/wallet';
  const response = await api.get<{ data: IWallet[] }>(url);
  if (response.status === 200) {
    return response.data;
  }
  throw new Error(response.data?.msg || 'failed');
}

export async function createWallet({
  title,
  max_customer_credits,
  max_daily_credits
}): Promise<common.Wallet[] | errors.APIError> {
  const response = await api.post<any, common.Wallet | errors.APIError>('/store/wallet', {
    name: 'changemida',
    mode: 'virtual',
    title,
    max_customer_credits: parseInt(max_customer_credits, 10),
    max_daily_credits: parseInt(max_daily_credits, 10)
  });
  if (response.status === 200) {
    return response.data;
  }
  throw new Error(response.data?.msg);
}

export async function editWallet(
  id,
  { title, description, minimumBalance, max_customer_credits, max_daily_credits }
): Promise<common.Wallet[] | errors.APIError> {
  const response = await api.put<any, common.Wallet | errors.APIError>(`/store/wallet/${id}`, {
    title,
    description,
    minimumBalance: parseInt(minimumBalance, 10),
    max_customer_credits: parseInt(max_customer_credits, 10),
    max_daily_credits: parseInt(max_daily_credits, 10)
  });
  if (response.status === 200) {
    return response.data;
  }
  throw new Error(response.data.error?.fields[0]?.description || response.data?.msg);
}
