import React from 'react';

export function Dashboard() {
  return (
    <div className="flex animate-pulse space-x-4 py-4 px-8">
      {/* <div className="h-10 w-10 rounded-full bg-gray-200" /> */}
      <div className="flex-1 space-y-6 py-1">
        <div className="flex-1 space-y-2 py-1">
          <div className="h-6 w-10/12 rounded bg-gray-200" />
          <div className="h-4 w-6/12 rounded bg-gray-200" />
        </div>
        <div className="flex-1 space-y-2 py-1">
          <div className="h-6 w-8/12 rounded bg-gray-200" />
          <div className="h-4 w-4/12 rounded bg-gray-200" />
        </div>
        <div className="space-y-3">
          <div className="h-72 w-64 rounded-xl bg-gray-200" />
        </div>
        <div className="space-y-3">
          <div className="h-24 w-full rounded bg-gray-200 lg:w-6/12" />
          <div className="h-24 w-full rounded bg-gray-200 lg:w-6/12" />
          <div className="h-24 w-full rounded bg-gray-200 lg:w-6/12" />
          <div className="h-24 w-full rounded bg-gray-200 lg:w-6/12" />
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
