import React from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
import tw from 'twin.macro';
import { Dashboard } from './Dashboard';

export interface LoadersProps {}

export const Loaders = {
  Dashboard
};
