import React, { useEffect, useState } from 'react';
import { Grid, Box, Heading, Paragraph, Text, Spinner } from 'grommet';
import { useQueryParam, StringParam } from 'use-query-params';
import useAuthenticated from 'hooks/useAuthenticated';
import api from 'services/api';
import useEnsureWallet from 'hooks/useEnsureWallet';
import Toast from 'components/Toast';
import { PhoneMoneyForm } from 'app/design/PhoneMoneyForm';
import { Balance } from 'business/models/wallet';

export default function disperse() {
  useAuthenticated();
  useEnsureWallet();

  const [transaction, setTransaction] = useState({});
  const [notification, setNotification] = useState(false);
  const [loading, setLoading] = useState(false);
  const [balance, setBalance] = useState<Balance>({});
  const [message, setMessage] = useState(null);

  const [walletID] = useQueryParam('id', StringParam);

  async function wallet() {
    setLoading(true);
    const resp = await api.get(`/store/balance/${walletID}`);
    if (resp.status === 200) {
      setBalance(resp.data);
    }
    setLoading(false);
  }
  useEffect(() => {
    wallet();
  }, [walletID]);

  const submit = async (data) => {
    setMessage(null);
    setTransaction({});
    setLoading(true);

    const resp = await api.post<any, common.Transaction>('/store/transactionrequest', {
      ...data,
      wallet: balance.wallet.id,
      mode: 'pay'
    });
    if (resp.status === 200) {
      setNotification(true);
      setTransaction(resp.data);
    } else {
      setNotification(true);
      setMessage(resp.data.msg);
    }
    setLoading(false);
  };
  return (
    <Box>
      <Grid fill="horizontal" width="100%" pad="large" columns="flex">
        <Box align="stretch" justify="center" direction="column">
          <Box
            align="start"
            justify="start"
            pad={{ bottom: 'small', left: 'small' }}
            direction="column">
            <Heading>Spend Change</Heading>
            <Paragraph size="xlarge" fill={false}>
              <Box direction="row" gap="small">
                <Text size="large">Send change to</Text>
                {loading ? (
                  <Spinner />
                ) : (
                  <Box gap="small" direction="row">
                    <Text size="large" weight="bolder" color="primary">
                      {balance?.wallet?.title}
                    </Text>
                    <Text size="large">wallet</Text>
                  </Box>
                )}
              </Box>
            </Paragraph>
          </Box>
          <PhoneMoneyForm
            mode="send"
            onSubmit={submit}
            loading={loading}
            submitText="Continue"
            wallet={balance.wallet}
          />
        </Box>
      </Grid>
      <Toast
        status={transaction.paired && transaction.paid ? 'normal' : 'warning'}
        visible={notification}
        onClose={() => setNotification(false)}
        title={transaction.paired && transaction.paid ? 'Change Sent!' : 'Failed'}
        message={message || transaction.sender_message}
      />
    </Box>
  );
}
