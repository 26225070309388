import { IWallet } from 'business/models/wallet';
import clsx from 'clsx';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

export interface WithdrawModalProps {
  wallet: IWallet;
  loading: boolean;
  onSubmit?: Function;
}

export function WithdrawModal({ wallet, loading, onSubmit }: WithdrawModalProps) {
  const balance = (wallet.balance.received - wallet.balance.paid) / 100;
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();
  const [amount, setAmount] = useState(0);
  const onAmountChange = (e) => {
    const newAmount = parseFloat(e.target.value);
    setAmount(newAmount);
  };

  const isCloseToMax = balance - amount < 10;
  const isExceedingMax = amount > balance;
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} className="mx-auto my-8 max-w-lg">
        <div className="mb-4">
          <label htmlFor="amount" className="mb-2 block font-bold text-gray-700">
            Amount to withdraw:
          </label>
          <input id="wallet" type="hidden" value={wallet.id} {...register('wallet')} />
          <input
            id="amount"
            type="number"
            step="1"
            min="1"
            max={balance.toFixed(2)}
            {...register('amount', {
              required: true,
              pattern: /^\d+(\.\d{1,2})?$/,
              min: 1,
              max: balance.toFixed(2)
            })}
            onChange={onAmountChange}
            className="w-full rounded-md border border-gray-300 px-3 py-2 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-400"
          />
          {isCloseToMax && !isExceedingMax && (
            <p className="mt-2 text-yellow-600">You are getting close to your withdrawal limit</p>
          )}
          {errors.amount?.type === 'required' && (
            <p className="mt-2 text-red-600">Please enter an amount</p>
          )}
          {errors.amount?.type === 'pattern' && (
            <p className="mt-2 text-red-600">Please enter a valid amount</p>
          )}
          {errors.amount?.type === 'min' && (
            <p className="mt-2 text-red-600">Withdrawal amount must be at least 0.01</p>
          )}
          {isExceedingMax && (
            <p className="mt-2 text-red-600">
              Withdrawal amount cannot exceed {balance.toFixed(2)}
            </p>
          )}
        </div>
        <button type="submit" className={clsx(loading && 'loading', 'btn btn-primary')}>
          Withdraw
        </button>
      </form>
    </div>
  );
}

WithdrawModal.defaultProps = {
  onSubmit: () => {}
};
