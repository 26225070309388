import { Organization } from './organization';
import User from './user';

export interface IMember {
  name?: string;
  phone?: string;
  email?: string;
  role?: string;
  status?: 'created' | 'done';
  created_at?;
  pull?: { shortLink: string };
  organization: Organization;
  user?: User;
}

export class Member implements IMember {
  name?: string | undefined;

  phone?: string | undefined;

  email?: string | undefined;

  role?: string | undefined;

  status?: 'created' | 'done' | undefined;

  created_at?: any;

  pull?: { shortLink: string } | undefined;

  organization: Organization;

  user?: User;

  public get org(): Organization {
    return Object.assign(new Organization(), this.organization);
  }
}
