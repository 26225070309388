import { Spinner } from 'grommet/components/Spinner';
import { FormNext, Search } from 'grommet-icons';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import clsx from 'clsx';
import { PhoneInput } from '../PhoneInput';

export interface PhoneMoneyFormProps {
  submitText?: string;
  loading: boolean;
  pinRequired?: boolean;
  onSubmit: any;
  onChange?: any;
  mode: 'send' | 'receive';
  customer?;
  wallet?: IWallet;
  reasons?;
}

export function PhoneMoneyForm({
  loading,
  onSubmit,
  submitText,
  pinRequired,
  wallet,
  reasons,
  mode
}: PhoneMoneyFormProps) {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm();

  const submit = (data) => {
    onSubmit({ ...data, amount: parseFloat(data.amount) * 100 });
  };
  return (
    <form onSubmit={handleSubmit(submit)} className="my-8 w-full">
      <div className="w-full space-y-2">
        <div className="form-control w-full">
          <label className="label">
            <span className="label-text text-md">Phone</span>
          </label>
          <PhoneInput
            required
            numberInputProps={{
              className: clsx('input input-bordered w-full', errors.phone ? 'input-error' : '')
            }}
            disabled={loading || pinRequired}
            {...register('phone', { required: 'Please enter a phone number' })}
            onChange={(val) => {
              setValue('phone', val);
            }}
          />
          {/* <button
          type="button"
          className="btn btn-ghost"
          disabled={loading || pinRequired}
          id="search-button"
          // onClick={() => setSearchFocused(true)}
        >
          <Search />
        </button> */}
          <label className="label">
            <span className="label-text-alt">{errors.phone?.message}</span>
          </label>
        </div>
        <div className="form-control w-full">
          <label className="label">
            <span className="label-text">Amount to receive</span>
          </label>
          <input
            type="number"
            className={clsx('input input-bordered', errors.amount ? 'input-error' : '')}
            disabled={pinRequired || loading}
            placeholder="Enter amount of credits"
            min={1}
            max={wallet?.max_customer_credits}
            {...register('amount', {
              required: 'Please enter an amount',
              min: { value: 1, message: 'Please enter an amount greater than 0' },
              max: {
                value: wallet?.max_customer_credits,
                message: `Please enter an amount less than or equal to ${wallet?.max_customer_credits}`
              }
            })}
          />
          <label className="label">
            <span className="label-text-alt">{errors.amount?.message}</span>
          </label>
        </div>
        <div className="form-control  w-full">
          <label className="label">
            <span className="label-text text-md">What is the reason?</span>
          </label>
          <textarea
            className={clsx('textarea textarea-bordered', errors.reason ? 'input-error' : '')}
            placeholder={`Enter a reason for ${mode === 'send' ? 'sending' : 'requesting'} credits`}
            disabled={loading}
            {...register('reason')}
          />
          <label className="label">
            <span className="label-text-alt">{errors.reason?.message}</span>
          </label>
          <div className="grid grid-cols-1 gap-2 py-2 lg:grid-cols-2">
            {reasons.map((reason) => (
              <button
                type="button"
                key={reason.value}
                className="btn btn-sm btn-outline lowercase"
                onClick={() => {
                  setValue('reason', reason.label);
                }}>
                {reason.label}
              </button>
            ))}
          </div>
        </div>
        <button type="submit" disabled={loading} className="btn btn-primary">
          {loading ? (
            <Spinner color="white" className="mr-3" />
          ) : (
            <FormNext className="mr-3 fill-white" />
          )}
          {submitText || 'Continue'}
        </button>
      </div>
    </form>
  );
}

PhoneMoneyForm.defaultProps = {
  submitText: 'submit',
  pinRequired: false,
  customer: null,
  onChange: () => {},
  storefronts: [],
  reasons: [
    { value: 'change', label: 'Giving out change' },
    { value: 'completion', label: 'Completing a purchase' },
    { value: 'payment', label: 'Paying for a product or service' }
  ]
};
