import React, { useContext, useEffect, useState } from 'react';
import { ResponsiveContext, Box, List, Text, Spinner } from 'grommet';
import { StatusCritical, StatusGood, Deliver, Package } from 'grommet-icons';
import format from 'date-fns/format';
// import { useFilters } from '../FilterControls';
import { useFilters } from '@packages/design/components';
import { useModal } from '../../hooks/useModal';
import { SmsCard } from '../SmsCard';
import { EngageRunList } from '../EngageRunList';
import { engageRuns } from '../../services/engage';

export interface ActivityProps {}

const statusIcons = {
  failed: <StatusCritical />,
  done: <StatusGood color="status-ok" />,
  processing: <Deliver />,
  created: <Package />
};

function ActivityRows({ focusedData }) {
  const [accompaniedRows, setAccompaniedRows] = useState([]);
  const [busy, setbusy] = useState(false);

  useEffect(() => {
    if (!focusedData) {
      setAccompaniedRows([]);
      return;
    }
    async function run() {
      setbusy(true);
      try {
        const allruns = await engageRuns(focusedData.id);
        setAccompaniedRows(allruns.data);
      } catch (error) {
        alert(error.message);
      }
      setbusy(false);
    }
    run();
  }, [focusedData]);

  if (busy) {
    return (
      <Box fill align="center" justify="center" pad="large">
        <Spinner />
      </Box>
    );
  }

  return <EngageRunList rows={focusedData ? accompaniedRows : []} />;
}

export function Activity({ children }: React.FC<ActivityProps>) {
  const size = useContext(ResponsiveContext);
  const { filteredResults } = useFilters();
  const [focusedData, setfocusedData] = useState(null);

  useEffect(() => {
    if (focusedData === null) return;
    show();
  }, [focusedData]);

  const { show, hide, RenderModal } = useModal(
    {
      title: 'SMS Report',
      onClose: () => {
        setfocusedData(null);
      }
    },
    'modal'
  );

  return (
    <Box pad="xxsmall">
      <List
        action={(item, index) => (
          <Box key={index} align="center" direction="row" gap="small">
            {!['xsmall', 'small'].includes(size) && <Text color="text-strong">{item.status}</Text>}
            {statusIcons[item.status]}
          </Box>
        )}
        // border="horizontal"
        data={filteredResults}
        onClickItem={(event) => {
          setfocusedData(event.item);
        }}>
        {(datum, index) => (
          <Box gap="xsmall" justify="between" key={index}>
            <Text color="text-strong" size="large" weight="bold" wordBreak="break-all">
              {datum.name}
            </Text>
            <Text color="text-strong" size="small">
              {datum.tool}
            </Text>
            <Text color="text-strong" weight="bold">
              {datum.data.title}
            </Text>
            <Text color="dark-2" size="small">
              {format(new Date(datum.created_at), 'MMM, dd, yyyy hh:mm a')}
            </Text>
          </Box>
        )}
      </List>
      <RenderModal>
        <Box
          background="light-2"
          pad="large"
          height={{ min: '300px' }}
          margin={{ bottom: 'medium' }}>
          <SmsCard {...focusedData?.data} />
        </Box>
        <ActivityRows focusedData={focusedData} />
      </RenderModal>
    </Box>
  );
}
