import React from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
import tw from 'twin.macro';

import { LottieOptions, useLottie } from 'lottie-react';
import successAnimation from '../../animations/79952-successful.json';
import { Button } from '../Button';

export interface SuccessModalProps {
  title: string;
  message: string;
  onClose: boolean;
}

const options: LottieOptions = {
  animationData: successAnimation,
  loop: false
};

export function SuccessModal({ title, message, onClose }: SuccessModalProps) {
  const { View: SuccessAnimation } = useLottie(options, {
    width: '100%',
    height: '50%',
    padding: 0,
    margin: 0
  });
  return (
    <div className="flex w-11/12 flex-col justify-center gap-5 self-center p-8 text-center lg:w-8/12">
      {SuccessAnimation}
      <h2 className="text-center text-2xl font-extrabold">{title}</h2>
      <p className="text-center">{message}</p>
      <div className="flex justify-center">
        <Button onClick={onClose} label="close" />
      </div>
    </div>
  );
}
