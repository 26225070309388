import {
  Box, Notification, Button, StatusType,
} from 'grommet';
import * as React from 'react';

function Toast({
  onClose,
  visible,
  title,
  message,
  status = 'normal',
}: {
  onClose: (...args: any[]) => any;
  visible: boolean;
  title: string;
  message: string;
  status?: StatusType;
}) {
  return (
    <>
      {visible && (
        <Notification
          toast
          status={status}
          title={title}
          message={message}
          onClose={onClose}
        />
      )}
    </>
  );
}

export default Toast;
