import { Balance, IWallet } from 'business/models/wallet';
import { Text, Card, CardBody, Box, NameValuePair, Heading, CardFooter, CardHeader } from 'grommet';
import { AddCircle, CreditCard, SubtractCircle } from 'grommet-icons/icons';
import React from 'react';
import { Button } from '../Button';
// import { styled } from 'nativewind';

// const Card = styled(Container);
export interface WalletTileProps {
  id: string;
  currency?: string;
  dummy?: boolean;
  Link;
  onAddCredit?;
  onWithdraw?;
  onEdit?;
  owner: string;
  paid: number;
  received: number;
  title: string;
  user: string;
  withActions?: boolean;
  isOwner;
  onSend;
  onReceive;
  // wallet: Balance;
}

function Identifier({ title, subtitle, icon }) {
  return (
    <Box direction="row" gap="18px" align="start">
      <Box pad={{ vertical: 'xsmall' }}>{icon}</Box>
      <Box>
        <Text color="text-strong" size="medium" weight="bold">
          {title}
        </Text>
        <Text size="small" weight="bold">
          {subtitle}
        </Text>
      </Box>
    </Box>
  );
}

export function MiniWalletTile({ title, wallet: { received, paid } }: WalletTileProps) {
  return (
    <div className="card bg-base-100 w-48 px-6 py-8 shadow-xl lg:w-56">
      <Identifier
        icon={<CreditCard size="medium" color="text-strong" />}
        title={title}
        subtitle={
          <Box margin={{ top: 'medium' }}>
            <Text size="medium">{`${Intl.NumberFormat('en-US').format(
              (received - paid) / 100
            )} credits`}</Text>
            <Text size="xsmall">{`NGN ${Intl.NumberFormat('en-US').format(
              (received - paid) / 100
            )}`}</Text>
          </Box>
        }
      />
      {/* <CardFooter align="center" justify="start">
      <Alert />
      <Text weight="bold">Expired</Text>
    </CardFooter> */}
    </div>
  );
}

export function WalletTile({
  user,
  onEdit,
  onAddCredit,
  onWithdraw,
  currency,
  withActions,
  isOwner,
  onSend,
  onReceive,
  ...rest
}: WalletTileProps) {
  const { id, received, paid, title, owner } = rest;
  return (
    <Card
      key={id}
      pad="0"
      alignSelf="start"
      margin={{ right: 'large' }}
      width={{ min: '280px', max: '340px' }}
      round="medium"
      background="dark-2"
      elevation="large"
      direction="column"
      animation={{ type: 'fadeIn', size: 'medium' }}>
      <CardHeader pad={{ horizontal: 'medium', top: 'medium' }}>
        <Text size="large" weight="bolder" wordBreak="break-word">
          {title}
        </Text>
        {withActions && isOwner && (
          <Button
            label="Edit"
            onClick={() =>
              onEdit({
                id,
                received,
                paid,
                title,
                owner
              })
            }
          />
        )}
      </CardHeader>
      <CardBody
        align="start"
        pad={{ horizontal: 'medium', top: 'small', bottom: 'medium' }}
        gap="small">
        <NameValuePair name={<Text>Credits</Text>}>
          <div className="flex flex-col">
            <Heading level="2" size="medium" margin="0" color="light-3">
              {Intl.NumberFormat('en-US').format((received - paid) / 100)}
            </Heading>
            <Text size="xsmall">Value</Text>
            <Text size="xsmall" weight="bold">
              {currency} {Intl.NumberFormat('en-US').format((received - paid) / 100)}
            </Text>
          </div>
        </NameValuePair>
        {withActions && (
          <div className="flex flex-row justify-evenly gap-2">
            <Button plain label="Top up" icon={<AddCircle size="16px" />} onClick={onAddCredit} />
            <Button
              plain
              label="Withdraw"
              icon={<SubtractCircle size="16px" />}
              onClick={onWithdraw}
            />
          </div>
        )}
      </CardBody>
      {withActions && (
        <CardFooter
          justify="stretch"
          direction="row"
          pad={{ horizontal: 'medium', vertical: 'small' }}
          background="dark-1"
          gap="medium"
          margin="0"
          round={{
            corner: 'bottom',
            size: 'small'
          }}>
          <button type="button" className="btn" onClick={() => onSend(id)}>
            Send
          </button>
          <button type="button" className="btn btn-ghost" onClick={() => onReceive(id)}>
            Receive
          </button>
        </CardFooter>
      )}
    </Card>
  );
}

WalletTile.defaultProps = {
  currency: 'NGN',
  withActions: false,
  onWithdraw: () => {},
  onAddCredit: () => {}
};
