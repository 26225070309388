import { navigate } from 'gatsby';
import * as React from 'react';
import { useLocalStorage } from 'usehooks-ts';
import api from 'services/api';

function useEnsureWallet() {
  const [loading, setLoading] = React.useState(false);
  const [session, setSession] = useLocalStorage('vsn', {});
  React.useEffect(() => {
    if (loading || (session.wallet && session.wallet?.id)) return;
    async function run() {
      setLoading(true);
      // get changemida wallet
      const r = await api.get(
        `/store/wallet?q={"owner":"${session.data?.id}","name":"changemida","mode":"locked"}`
      );
      if (r.data?.data.length > 0) {
        setSession({ ...session, wallet: r.data.data[0] });
      } else {
        const r2 = await api.post('/store/wallet', {
          name: 'changemida',
          mode: 'locked'
        });
        if (r2.status === 200) {
          setSession({ ...session, wallet: r2.data });
        }
      }
      setLoading(false);
    }
    // run();
  }, []);
  return session;
}

export default useEnsureWallet;
