import { navigate } from 'gatsby';
import * as React from 'react';
import { useLocalStorage } from 'usehooks-ts';

function useAuthenticated(redirect?: string, redirectIfLoggedIn?: string, location?) {
  const [session, setSession] = useLocalStorage('vsn', {});
  React.useEffect(() => {
    if (location?.pathname.includes('auth')) {
      return;
    }
    if (!redirectIfLoggedIn && !session?.token) {
      navigate('/auth/signin');
    } else if (redirectIfLoggedIn) {
      navigate(redirect || '/');
    }
  }, [session]);
  return [session as common.Session, setSession];
}

export default useAuthenticated;
