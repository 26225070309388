import React, { useContext } from 'react';
import { Box, Button, Footer, Heading, Header, Layer, Paragraph, ResponsiveContext } from 'grommet';
import { FormClose } from 'grommet-icons';
import { createPortal } from 'react-dom';
import tw from 'twin.macro';
import styled from 'styled-components';
import { LogoIcon } from '../LogoIcon';

export interface ModalDialogProps {
  elementId: string;
  children?;
  title?;
  subtitle?;
  onClose?;
  layerProps?;
}

const StyledLayer = styled(Layer)(() => [
  // tw`sm:w-full rounded-none`,
  tw`w-full`,
  tw`md:w-7/12`,
  tw`lg:w-5/12`
]);

export const ModalDialog: React.FC<ModalDialogProps> = ({
  elementId,
  children,
  title,
  subtitle,
  onClose
}): JSX.Element => {
  const size = useContext(ResponsiveContext);
  return createPortal(
    <StyledLayer
      className="md:h-screen-md scrollbar-hide h-screen overflow-scroll "
      onClickOutside={onClose}
      responsive={false}
      position={!['xsmall', 'small'].includes(size) ? 'center' : undefined}
      full={!!['xsmall', 'small'].includes(size)}
      animation={['xsmall', 'small'].includes(size) ? 'fadeIn' : 'slide'}>
      <Box flex="grow" className="transition-all">
        <Header
          className="bg-base-100 sticky top-0"
          align="center"
          pad={{ horizontal: 'medium', vertical: 'medium' }}
          justify="between"
          border={{ side: 'bottom', color: 'light-2' }}>
          <Box justify="center" align="start">
            {/* <LogoIcon full /> */}
            {title && (
              <Heading level={3} size="small" margin="none">
                {title}
              </Heading>
            )}
            {subtitle && (
              <Paragraph
                size="small"
                fill
                margin={{
                  top: 'small',
                  horizontal: '0',
                  bottom: '0'
                }}>
                {subtitle}
              </Paragraph>
            )}
          </Box>
          {onClose && (
            <Button a11yTitle="Close modal" icon={<FormClose size="medium" />} onClick={onClose} />
          )}
        </Header>
        {children}
      </Box>
    </StyledLayer>,
    document.getElementById(elementId) as HTMLElement
  );
};

export function ModalBody({ children, ...boxProps }) {
  return <Box {...boxProps}>{children}</Box>;
}

export function ModalFooter({ children, ...boxProps }) {
  return <Footer {...boxProps}>{children}</Footer>;
}
