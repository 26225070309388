import { Script } from 'gatsby';
import { Box, Grommet } from 'grommet';
import { StateMachineProvider } from 'little-state-machine';
import * as React from 'react';
import { Toaster } from 'react-hot-toast';
import Layout from 'components/Layout';
import { GlobalStyle } from 'styles/GlobalStyles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ErrorProvider from 'context/error';
import { AuthProvider } from '../context/auth';
import { OrganizationProvider } from '../context/organization';
import { theme } from '../themes/main';
import 'reactjs-freshchat/dist/index.css';

const queryClient = new QueryClient();

if (!Object.entries) {
  Object.entries = function (obj) {
    const ownProps = Object.keys(obj);
    let i = ownProps.length;
    const resArray = new Array(i); // preallocate the Array
    while (i--) resArray[i] = [ownProps[i], obj[ownProps[i]]];
    return resArray;
  };
}

export const wrapRootElement = ({ element }): JSX.Element => (
  <>
    <GlobalStyle />
    {/* <Script src="//fw-cdn.com/2018258/2767246.js" chat="true" /> */}
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
    />
    <style>
      @import
      url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;600;700;800;900&display=swap');
    </style>
    <noscript>You need to enable JavaScript to run this app.</noscript>
    <QueryClientProvider client={queryClient}>
      <Grommet theme={theme}>{element}</Grommet>
    </QueryClientProvider>
  </>
);

export const wrapPageElement = ({ element, props }) => (
  <>
    {/* <Script type="text/javascript" id="hs-script-loader" async defer src="//js-eu1.hs-scripts.com/26066737.js" /> */}
    <ErrorProvider>
      <StateMachineProvider>
        <AuthProvider location={props.location}>
          <OrganizationProvider>
            <Layout>{element}</Layout>
          </OrganizationProvider>
        </AuthProvider>
      </StateMachineProvider>
    </ErrorProvider>
    <Box id="modal" />
    <Toaster />
  </>
);
