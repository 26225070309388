import Centrifuge from 'centrifuge';
import api from 'services/api';
import { createCredentials, subscribeToProvateStore } from 'services/push';

let centrifuge: Centrifuge = null;
let subscription = null;

export const MessageListener = (onReceiveMessage?: (arg0: any) => any, onConnect?, onDisconnect?) => ({
  channel: '',
  onConnect(context) {
    // //console.tron.log(`${this.channel} Connection(${context.latency} ms)`);
    onConnect && onConnect(context);
  },
  onDisconnect(context) {
    // //console.tron.log(`${this.channel} disconnect ${context.reason}`);
    onDisconnect && onDisconnect(context);
  },
  publish: (dataset) => {
    onReceiveMessage && onReceiveMessage(dataset);
  },
  join: (message) => {
    // console.tron.log("JOIN: " + JSON.stringify(message));
  },
  presence: (message) => {
    // console.tron.log("PRESENCE: " + JSON.stringify(message));
  },
  leave: (message) => {
    console.log(`LEAVE: ${JSON.stringify(message)}`);
  },
  subscribe: (context) => {
    // //console.tron.log("SUBSCRIBE: " + JSON.stringify(context));
  },
  error: (errContext) => {
    // console.tron.log("ERROR: " + JSON.stringify(errContext));
  },
  unsubscribe: (context) => {
    // console.tron.log("UNSUBSCRIBE: " + JSON.stringify(context));
  },
});

export async function setupPush(listener) {
  const credentials = await createCredentials();
  const { channel, _realtime_token } = credentials;
  centrifuge = new Centrifuge('wss://realtime.dostow.com/connection/websocket', {
    debug: true,
    subscribeEndpoint: 'https://api.dostow.com/v1/store/notificationChannel',
    subscribeHeaders: api.headers,
    onPrivateSubscribe(ctx, cb) {
      subscribeToProvateStore(ctx.data)
        .then((rsp) => cb({ status: 200, data: rsp }));
    },
  });
  centrifuge.setToken(_realtime_token);
  subscription = centrifuge.subscribe(channel, listener);
  centrifuge.on('connect', () => {
  });
  centrifuge.on('disconnect', () => {
  });
  centrifuge.on('error', (err) => {
    console.error(err);
  });
  centrifuge.connect();
  return centrifuge;
}
