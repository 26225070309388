import React from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
import tw from 'twin.macro';

export interface PersonalFormProps {
  register;
  loading: boolean;
}

export function PersonalForm({ register }: PersonalFormProps) {
  return (
    <div className="mt-10 sm:mt-0">
      <div className="md:gap-6">
        <div className="md:col-span-1">
          <div className="px-4 sm:px-0">
            <h3 className="text-lg font-medium leading-6 text-gray-900">Personal Information</h3>
            <p className="mt-1 text-sm text-gray-600">
              Use a permanent address where you can receive mail.
            </p>
          </div>
        </div>
        <div className="mt-5 md:col-span-2 md:mt-0">
          <div>
            <div className="overflow-hidden sm:rounded-md">
              <div className="bg-white py-5 sm:p-6">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-3">
                    <label htmlFor="first-name" className="label">
                      First name
                    </label>
                    <input
                      type="text"
                      autoComplete="given-name"
                      className="input input input-bordered mt-1 block w-full"
                      {...register('firstName')}
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <label htmlFor="last-name" className="label">
                      Last name
                    </label>
                    <input
                      type="text"
                      autoComplete="family-name"
                      className="input input-bordered mt-1 w-full"
                      {...register('lastName')}
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <label htmlFor="email-address" className="label">
                      Email address
                    </label>
                    <input
                      type="email"
                      autoComplete="email"
                      className="input input-bordered mt-1 w-full"
                      {...register('Email')}
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <label htmlFor="gender" className="label">
                      Gender
                    </label>
                    <select
                      id="gender"
                      name="gender"
                      autoComplete="gender-name"
                      defaultValue="Male"
                      className="select select-bordered mt-1 w-full"
                      {...register('gender')}>
                      <option>Female</option>
                      <option>Male</option>
                      <option>Other</option>
                    </select>
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <label htmlFor="country" className="label">
                      Country
                    </label>
                    <select
                      id="country"
                      name="country"
                      autoComplete="country-name"
                      className="select  select-bordered mt-1 w-full"
                      {...register('country')}>
                      <option>Nigeria</option>
                      <option>Ghana</option>
                      <option>Tanzania</option>
                    </select>
                  </div>
                  <div className="col-span-6">
                    <label htmlFor="street-address" className="label">
                      Street address
                    </label>
                    <input
                      type="text"
                      name="street-address"
                      id="street-address"
                      autoComplete="street-address"
                      className="input input-bordered mt-1 w-full"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                    <label htmlFor="city" className="label">
                      City
                    </label>
                    <input
                      type="text"
                      name="city"
                      id="city"
                      autoComplete="address-level2"
                      className="input input-bordered mt-1 w-full"
                      {...register('city')}
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                    <label htmlFor="region" className="label">
                      State / Province
                    </label>
                    <input
                      type="text"
                      name="region"
                      id="region"
                      autoComplete="address-level1"
                      className="input input input-bordered mt-1 block w-full"
                      {...register('state')}
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                    <label htmlFor="postal-code" className="label">
                      ZIP / Postal code
                    </label>
                    <input
                      type="text"
                      name="postal-code"
                      id="postal-code"
                      autoComplete="postal-code"
                      className="input input-bordered mt-1 block w-full"
                      {...register('postalCode')}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
