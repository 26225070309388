import React, { useState } from 'react';
import { Box } from 'grommet/components/Box';
import {
  Button, FormField, Page, PageHeader, TextArea, Notification, Spinner, Tag, Text, TextInput,
} from 'grommet';
import { navigate } from 'gatsby';
import toast from 'react-hot-toast';
import {
  BackButton, HorizontalList, SmsCard, WizardForm,
} from '../../components';
import { createEngage } from '../../services/engage';

export function Tools() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<{ title?, body? }>({});
  const [previewData, setPreviewData] = useState<{ title?, body? }>({});
  const [error, setError] = useState(null);
  const [templates] = useState([
    {
      name: 'Independence Day Wish',
      data: {
        title: '🇳🇬 Happy Independence Day 🥳🎆🇺🇸🎉',
        body: `We are proud to be a part of our glorious nation… And we hoist the green flag high… Sending you warm wishes… on Happy Independence Day!

I wish you a happy Independence Day! May our country progress in everything, so that the whole world is proud of us.`,
      },
    }, {
      name: 'HAPPY NEW MONTH',
      data: {
        title: 'Moving into the new month',
        body: 'Don\'t forget to stop by our stores for your groceries',
      },
    }, {
      name: 'Happy New Week',
      data: {
        title: 'Happy New Week',
        body: 'Wishing you a fantastic new week, filled with exciting experiences and great opportunities! Happy new week, dear friend!',
      },
    },
  ]);

  const onChange = (values) => {
    setData(values);
  };
  const onSubmit = async (data) => {
    setLoading(true);
    try {
      await toast.promise(
        createEngage({ tool: 'sms', data: data.value, customers: { filters: {} } }),
        {
          loading: 'creating sms...',
          success: 'completed',
          error: (error) => error.message,
        },
      );
      navigate('/engage');
    } catch (error) {
      // setError(error.message);
    }
    setLoading(false);
  };
  return (
    <Page
      title="Send SMS"
      pad={{ horizontal: 'large', top: 'large' }}
      fill
    >
      <BackButton />
      <PageHeader
        title="Send SMS"
      />
      <Box pad={{ bottom: 'medium' }}>
        <Text size="small" weight="bold">
          Quick messages
        </Text>
        <Box direction="row" pad={{ vertical: 'small' }} wrap>
          {templates.map((t, index) => {
            // const [name] = Object.keys(t);
            const [value] = Object.values(t);
            return (
              <Tag
                key={index}
                size="small"
                value={value}
                margin={{ right: 'small', vertical: 'xsmall' }}
                onClick={() => {
                  setData(t.data);
                }}
                onMouseEnter={() => setPreviewData(t.data)}
                onMouseLeave={() => {
                  setPreviewData(null);
                }}
              />
            );
          })}
        </Box>
      </Box>
      <Box direction="row-responsive" gap="large" fill="horizontal">
        <SmsCard {...(previewData || data)} />
        <WizardForm
          value={data}
          loading={loading}
          onChange={onChange}
          onSubmit={onSubmit}
          steps={[
            {
              inputs: (
                <Box width={{ min: 'medium' }}>
                  <FormField type="text" name="title" label="Title" required disabled={loading}>
                    <TextInput name="title" disabled={loading} />
                  </FormField>
                  <FormField type="textarea" name="body" label="Body" required disabled={loading}>
                    <TextArea name="body" disabled={loading} />
                  </FormField>
                  <Button icon={loading && <Spinner />} type="submit" disabled={loading} name="sms" label="Send SMS" primary margin={{ top: 'small' }} />
                </Box>
              ),
            },
          ]}
        />
      </Box>
      {/* <FilteredDataList /> */}
      {error && (
        <Notification
          toast
          status="critical"
          message={error}
          onClose={() => setError(null)}
        />
      )}
    </Page>
  );
}

Tools.propTypes = {};

export default Tools;
