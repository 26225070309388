import {
  ResponsiveContext,
  Box,
  Select,
  Form,
  FormField,
  TextInput,
  TextArea,
  PageHeader,
  Text,
  Spinner
} from 'grommet';
import { CircleAlert } from 'grommet-icons';
import React, { useContext, useEffect, useState } from 'react';
import { FileInput } from 'app/design/FileInput';
import { PhoneInput } from 'app/design/PhoneInput/PhoneInput';
import { Button } from 'app/design/Button';

export interface OrganizationSignupProps {
  onCreate;
  data?;
  busy?: boolean;
  error?: string;
  onUpload?;
}

export function OrganizationSignupForm({
  busy,
  data,
  onCreate,
  onUpload,
  error
}: OrganizationSignupProps) {
  const businessTypes = ['Supermarket', 'Barbing Salon', 'Salon', 'Eatery', 'Restaurant', 'Other'];
  const [formValues, setFormValues] = React.useState(
    data || {
      name: '',
      description: '',
      type: '',
      email: '',
      phone: '',
      address: ''
    }
  );
  const size = useContext(ResponsiveContext);
  // Set FormLevelError is set to true for display purposes
  // this should be set to false then api call will change state
  // eslint-disable-next-line no-unused-vars
  const [showFormLevelError, setShowFormLevelError] = React.useState();
  const onFormChange = (value) => {
    setFormValues(value);
  };
  // eslint-disable-next-line no-unused-vars
  const onSubmit = ({ value, touched }) => {
    if (busy) return;
    onCreate(formValues);
  };

  const [numFiles, setNumFiles] = useState(0);
  useEffect(() => {
    setFormValues(data);
  }, [data]);

  return (
    <Box gap="medium" width="medium">
      <Box
        // Padding used to prevent focus from being cutoff
        pad={{ horizontal: 'xxsmall' }}>
        <Form
          messages={{
            required: 'This is a required field.'
          }}
          onSubmit={({ value, touched }) => onSubmit({ value, touched })}
          value={formValues}
          onChange={onFormChange}
          method="post">
          <FileInput
            value={data?.banner_file}
            onChange={(e) => {
              setNumFiles(e.target.files?.length);
              onUpload(e.target.files);
            }}
          />
          <FormField required htmlFor="name" name="name" disabled={busy} label="Business Name">
            <TextInput id="name" name="name" disabled={busy} />
          </FormField>
          <FormField required htmlFor="type" name="type" label="Type of Business" disabled={busy}>
            <Select
              id="type"
              name="type"
              options={businessTypes}
              defaultValue={businessTypes[0]}
              disabled={busy}
            />
          </FormField>
          <FormField htmlFor="email" name="email" label="Email" disabled={busy}>
            <TextInput id="email" name="email" disabled={busy} />
          </FormField>
          <FormField
            htmlFor="phone"
            name="phone"
            label="Phone"
            disabled={busy}
            // validate={{
            //   regexp: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
            //   message: 'Enter a valid phone number'
            // }}
          >
            <PhoneInput
              value={formValues?.phone}
              name="phone"
              disabled={busy}
              onChange={(phone) => {
                setFormValues({ ...formValues, phone });
              }}
            />
          </FormField>
          <FormField
            htmlFor="description"
            name="description"
            label="Description (optional)"
            disabled={busy}>
            <TextArea
              id="description"
              name="description"
              placeholder="Description"
              resize="vertical"
              disabled={busy}
            />
          </FormField>
          {/* <FormField
            htmlFor="banner"
            name="banner"
            label="Upload a business photo for your banner"
            disabled={busy}>
            <FileInput
              messages={{
                dropPrompt: 'Drag and drop',
                browse: numFiles > 0 ? 'Replace File' : 'Select Photo'
              }}
              onChange={(event, { files }) => {
                setNumFiles(files?.length);
                onUpload(files);
              }}
              name="banner"
              disabled={busy}
              renderFile={(blob, v) => {
                const f = URL.createObjectURL(blob);
                return <img className="h-28 object-contain" src={f} />;
              }}
            />
          </FormField> */}
          {/* Show error if api call came back as an error  */}
          {error && (
            <Box
              margin={{ top: 'medium', bottom: 'medium' }}
              round="4px"
              pad="small"
              background="validation-critical"
              direction="row"
              gap="xsmall">
              <Box flex={false} margin={{ top: 'hair' }} pad={{ top: 'xxsmall' }}>
                <CircleAlert size="small" />
              </Box>
              <Text size="xsmall">{error.message}</Text>
            </Box>
          )}
          <Box
            align={!['xsmall', 'small'].includes(size) ? 'start' : undefined}
            margin={{ top: 'medium', bottom: 'small' }}>
            <Button
              label={`${data ? 'Edit' : 'Create'} your business`}
              type="submit"
              loading={busy}
            />
          </Box>
        </Form>
      </Box>
    </Box>
  );
}

export function OrganizationSignup({ data, ...props }: OrganizationSignupProps) {
  return (
    <Box pad={{ horizontal: 'large', bottom: 'large' }}>
      <PageHeader
        margin={{ bottom: 'medium' }}
        title={`${data ? 'Edit' : 'Create'} your business`}
        subtitle="Tell us about your business."
      />
      <OrganizationSignupForm data={data} {...props} />
    </Box>
  );
}
