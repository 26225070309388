import { Box, Spinner } from 'grommet';
import React, { useEffect } from 'react';
import { CustomerList } from 'components';
import { allCustomers } from 'services/customers';

export default function customers() {
  const [customers, setCustomers] = React.useState<common.Customer[]>([]);
  const [loading, setLoading] = React.useState(false);

  async function getCustomers(page = 0, count = 10) {
    setLoading(true);
    try {
      const resp = await allCustomers(page * count);
      setCustomers(resp.data);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }

  useEffect(() => {
    getCustomers();
  }, []);

  return (
    <Box pad={{ horizontal: 'medium', vertical: 'medium' }}>
      {loading ? (
        <Box align="center" justify="center" pad="xlarge">
          {' '}
          <Spinner />
        </Box>
      ) : (
        <CustomerList records={customers} />
      )}
    </Box>
  );
}
