import React from 'react';
import { LogoIcon } from '../LogoIcon';

import 'twin.macro';

export interface NamespaceFormProps {
  register;
  loading: boolean;
  errors;
}

export function NamespaceForm({ register, errors, loading }: NamespaceFormProps) {
  return (
    <div>
      <div className="md:gap-6">
        <div className="grid grid-cols-6 gap-4">
          <div className="col-span-6">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">Username</h3>
              <p className="mt-1 text-sm text-gray-600">This is your unique name on changemida.</p>
            </div>
          </div>
          <div className="col-span-6 sm:col-span-3">
            <div className="space-y-6 bg-white py-2 sm:p-6">
              <label className="label block font-medium text-gray-700" htmlFor="namespace">
                <div className="flex flex-row items-center gap-2">
                  <LogoIcon />
                  <span className="label">username (#oruko)</span>
                </div>
                <input
                  type="text"
                  placeholder="username"
                  autoComplete="namespace"
                  className="input input input-bordered mt-1 block w-full"
                  {...register('namespace', { disabled: loading, required: true })}
                />
                {errors.namespace && (
                  <span className="pt-2 font-light text-red-700">{errors.namespace?.message}</span>
                )}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
