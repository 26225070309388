import { Box, TextInput, TextInputProps } from 'grommet';
import React, { useEffect, useRef, useState } from 'react';
import useDigitInput from '../../hooks/useDigitInput';

export interface PinInputProps extends TextInputProps {
  digitLength?
  value?:string
  loading?:boolean
}

export function PinInput({
  ref,
  digitLength, loading, onChange, value, ...rest
}:PinInputProps) {
  const digits = useDigitInput({
    acceptedCharacters: /^[0-9]$/,
    length: digitLength,
    value,
    onChange: (v) => {
      const vv = v.trim();
      onChange({ target: { value: vv } });
    },
  });

  return (
    <Box direction="row" gap="small" animation="fadeIn">
      {digits.map((digit, k) => (
        <TextInput
          key={`${k}`}
          width="100%"
          inputMode="decimal"
          autoFocus={!loading && k === 0}
          {...(k === 0 ? { ref } : {})}
          {...rest}
          {...digit}
        />
      ))}
    </Box>
  );
}

PinInput.defaultProps = {
  digitLength: 6,
  value: '',
  loading: false,
};
