import { Box, Spinner } from 'grommet';
import React, { useEffect, useState } from 'react';
import { StringParam, useQueryParams } from 'use-query-params';
import toast from 'react-hot-toast';
import { navigate } from 'gatsby';
import { verifyPayment } from '../../services/payment';

export default function verify() {
  const [query, setQuery] = useQueryParams({
    id: StringParam, r: StringParam, trxref: StringParam, reference: StringParam,
  });
  const [busy, setBusy] = useState(true);

  useEffect(() => {
    async function run() {
      try {
        const { pull: { data: { status, gateway_response } } } = await verifyPayment({ payment_id: query.id });
        if (status === 'success') {
          navigate(`/?t=payment_verify&id=${query.id}`);
        }
      } catch (error) {
        toast.error(error.message);
      }
    }
    run();
  }, [query]);

  return (
    <Box pad="large">
      {busy && <Spinner />}
    </Box>
  );
}
