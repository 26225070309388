import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Footer, ResponsiveContext, ThemeContext } from 'grommet';

import { useFilters } from '.';
import { FilterCheckBoxGroup, FilterRangeSelector } from '../FilterTypes';

export function FiltersPanel() {
  const size = useContext(ResponsiveContext);
  const { applyFilters, data, filters, setFilters, setFiltersLayer, filterAttributes, layerProps } =
    useFilters();

  const { containerProps, contentProps } = { ...layerProps };

  const [filterCount, setFilterCount] = useState(0);

  // Provide indication for the number of filters applied
  useEffect(() => {
    let count = 0;

    // wait to update count until user completes setting their desired filters
    Object.entries(filters).forEach((filter) => {
      // filter can either be an array or an object with a value property,
      // check both
      if (filter[1].length > 0 || (filter[1].value && filter[1].value.length)) count += 1;
    });
    setFilterCount(count);
  }, [filters, setFilterCount]);

  return (
    <Box gap="medium" pad={{ vertical: 'medium' }} {...containerProps}>
      <Box pad="medium" flex overflow="auto" {...contentProps}>
        {filterAttributes &&
          filterAttributes.map((attr) => {
            if (attr.filterType === 'CheckBoxGroup') {
              return <FilterCheckBoxGroup key={attr.property} attr={attr} />;
            }
            if (attr.filterType === 'RangeSelector') {
              return <FilterRangeSelector key={attr.property} attr={attr} />;
            }
            return null;
          })}
      </Box>
      <Footer
        justify="start"
        gap="small"
        direction="row-responsive"
        pad={{ horizontal: 'medium', bottom: 'small' }}>
        <Button
          label="Apply"
          badge={
            filterCount > 0
              ? {
                value: filterCount
                }
              : null
          }
          onClick={() => {
            applyFilters(data, filters);
            setFiltersLayer(false);
          }}
          primary
        />
        <Button
          label="Clear"
          onClick={() => {
            const nextFilters = {};
            /* Remove all filters, but do not apply until "Apply Filters"
                           is clicked. */
            setFilters(nextFilters);
          }}
        />
      </Footer>
    </Box>
  );
}
