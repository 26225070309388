import api from './api';
import { UploadResult } from './api.types';

export async function uploadPhoto(
  id: any,
  type: any,
  store: any,
  field: string,
  file,
  onUploadProgress: any
): Promise<UploadResult> {
  // make the api call
  const url = `/upload/${store}/${id}/${field}`;

  const formData = new FormData();
  formData.append('file', file);

  formData.append('name', field);
  formData.append('type', type);
  const response = await api.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });

  if (response.status === 200) {
    return response.data;
  }
  throw new Error(response.data.error?.fields[0]?.description || response.data?.msg);
}

export default uploadPhoto;
