import api from './api';

export async function createEngage(engage: common.Engage): Promise<common.Engage | errors.APIError> {
  const response = await api.post<common.Engage, any | errors.APIError>(
    '/store/engage',
    engage,
  );
  if (response.status === 200) {
    return response.data;
  }
  throw new Error(response.data.msg);
}
export async function allEngage(): Promise<{ data: common.Engage[] } | errors.APIError> {
  const response = await api.get<any, any | errors.APIError>('/store/engage');
  if (response.status === 200) {
    return response.data;
  }
  throw new Error(response.data.msg);
}
export async function engageRuns(engageID: string): Promise<{ data: common.EngageRun[] } | errors.APIError> {
  const response = await api.get<any, any | errors.APIError>(`/store/engagerun?q={"engage_id":"${engageID}"}`);
  if (response.status === 200) {
    return response.data;
  }
  throw new Error(response.data.msg);
}
