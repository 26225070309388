import { Menu, More } from 'grommet-icons';
import React from 'react';

export interface ActionMenuProps {
  items
}

export function ActionMenu({ items }: ActionMenuProps): JSX.Element {
  return (
    <Menu
      dropAlign={{ top: 'bottom', right: 'right' }}
      items={items}
      icon={<More />}
    />
  );
}
