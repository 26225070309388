import React, { useContext, useEffect, useState } from 'react';
import {
  Anchor,
  Box,
  Button,
  Footer,
  Header,
  Heading,
  Layer,
  ResponsiveContext,
  Text,
} from 'grommet';
import { FormClose } from 'grommet-icons';
import { FilterCheckBoxGroup, FilterRangeSelector } from '../FilterTypes';
import { useFilters } from './FiltersContext';

export interface FiltersLayerProps {}

export function FiltersLayer({}:FiltersLayerProps) {
  const size = useContext(ResponsiveContext);
  const {
    applyFilters,
    data,
    filters,
    setFilters,
    setFiltersLayer,
    filterAttributes,
    layerProps,
    previousFilters,
  } = useFilters();

  const { containerProps, contentProps } = { ...layerProps };

  const closeLayer = () => {
    /* User has not applied new filter settings, restore to previous state. */
    setFilters(previousFilters);
    setFiltersLayer(false);
  };

  const [filterCount, setFilterCount] = useState(0);
  // Provide indication for the number of filters applied
  useEffect(() => {
    let count = 0;

    // wait to update count until user completes setting their desired filters
    Object.entries(filters).forEach((filter) => {
      // filter can either be an array or an object with a value property,
      // check both
      if (filter[1].length > 0 || (filter[1].value && filter[1].value.length)) count += 1;
    });
    setFilterCount(count);
  }, [filters, setFilterCount]);

  return (
    <Layer
      as="section"
      responsive={false}
      position={!['xsmall', 'small'].includes(size) ? 'right' : undefined}
      full={['xsmall', 'small'].includes(size) ? true : 'vertical'}
      onClickOutside={() => closeLayer()}
      onEsc={() => closeLayer()}
      animation={['xsmall', 'small'].includes(size) ? 'fadeIn' : 'slide'}
      {...layerProps}
    >
      <Box
        fill="vertical"
        gap="medium"
        pad={'medium'}
        width={{
          min: !['xsmall', 'small'].includes(size) ? 'medium' : undefined,
        }}
        {...containerProps}
      >
        <Header
          pad="medium"
          border={{ side: 'bottom', size: '2px', color: 'light-3' }}
        >
          <Heading margin="none" level="3">Filters</Heading>
          <Button icon={<FormClose />} onClick={() => closeLayer()} />
        </Header>
        <Box pad="medium" flex overflow="auto" {...contentProps}>
          {filterAttributes
              && filterAttributes.map((attr) => {
                if (attr.filterType === 'CheckBoxGroup') {
                  return <FilterCheckBoxGroup key={attr.property} attr={attr} />;
                }
                if (attr.filterType === 'RangeSelector') {
                  return <FilterRangeSelector key={attr.property} attr={attr} />;
                }
                return null;
              })}
        </Box>
        <Footer
          direction="column"
          justify="start"
          height="100px"
          gap="small"
          pad={{ horizontal: 'medium', vertical: 'medium' }}
          border={{ side: 'top', size: '2px', color: 'light-3' }}
        >
          <Box justify="between" direction="row" fill="horizontal" pad={{ bottom: 'small' }}>
            <Text size="small" weight="bold">
              {`${filterCount} filter${filterCount > 1 ? 's' : ''} selected`}
            </Text>
            <Anchor
              label="Clear Filters"
              size="small"
              onClick={() => {
                const nextFilters = {};
                /* Remove all filters, but do not apply until "Apply Filters"
                                is clicked. */
                setFilters(nextFilters);
                // onApplyFilter(filters)
              }}
            />
          </Box>
          <Button
            fill="horizontal"
            label="Apply"
            onClick={() => {
              applyFilters(data, filters);
              setFiltersLayer(false);
            }}
            primary
          />
        </Footer>
      </Box>
    </Layer>
  );
}
