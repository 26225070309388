import React, { useState } from 'react';
import { ModalDialog } from '../design/ModalDialog';

export interface UseModalProps {
  title?;
  subtitle?;
  onClose?;
  initialVisible?: boolean;
}
// Renders a modal to the modal root and handles the visibility state
// of this modal.
//
// NOTE: Each modal you want to render should use a separate hook!!!
// Otherwise your modals will share their visibility state which might lead
// to overlapping and unclosable elements.
export const useModal = (
  { title, subtitle, onClose, initialVisible }: UseModalProps,
  elementId = 'modal'
): {
  show: () => void;
  hide: () => void;
  RenderModal: ({ children }: { children: React.ReactChild }) => JSX.Element;
} => {
  const [isVisible, setIsVisible] = useState(initialVisible);

  const show = () => setIsVisible(true);
  const hide = () => {
    setIsVisible(false);
    if (onClose) onClose();
  };

  function RenderModal({ children }) {
    if (isVisible) {
      return (
        <ModalDialog
          title={title}
          subtitle={subtitle}
          onClose={hide}
          elementId={elementId}
          layerProps={{ animation: 'slideIn' }}>
          {typeof children === 'function' ? children() : children}
        </ModalDialog>
      );
    }
    return <div />;
  }

  return {
    show,
    hide,
    RenderModal
  };
};

useModal.defaultProps = { initialVisible: false };
