export const ufirst = (t: string) => t.charAt(0).toUpperCase() + t.slice(1);

interface Filter {
  locations?: { state: string; country: string }[];
  prices?: Record<string, any>[];
  types?: Record<string, any>[];
  categories?: Record<string, any>[];
  organizers?: Record<string, any>[];
}

export function makeFilterConfig(filterFields: Filter) {
  const filtersConfig = [];
  const keys = Object.keys(filterFields);
  for (let index = 0; index < keys.length; index++) {
    const fieldName = keys[index];
    const values = filterFields[fieldName];
    if (values === null) continue;
    if (typeof values !== 'undefined' && values.length !== 0) {
      filtersConfig.push({
        property: fieldName,
        label: ufirst(fieldName),
        max: 5,
        filterType: 'CheckBoxGroup',
        filterOptions: values,
      });
    }
  }
  return filtersConfig;
}
