import clsx from 'clsx';
import React from 'react';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  label?: string;
  loading?: boolean;
  plain?: boolean;
  icon?;
  onClick?;
  type?;
  disabled?;
}

export function Button({
  label,
  icon,
  plain,
  loading,
  disabled,
  type,
  children,
  ...props
}: ButtonProps) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <button
      type={type}
      disabled={disabled}
      className={clsx(
        'btn btn-primary flex gap-2',
        plain && 'btn-ghost',
        loading && 'loading',
        disabled && ' btn-disabled'
      )}
      {...props}>
      {icon}
      {label}
      {children}
    </button>
  );
}

Button.defaultProps = {
  label: '',
  icon: null,
  plain: false,
  loading: false,
  onClick: () => {}
};

export default Button;
