import React from 'react';

export default function ImageAvatar({ organizationAvatar, userAvatar }: ImageAvatarProps) {
  return (
    <div>
      <div className="relative text-center">
        <img
          alt="organization"
          className="inset-0 w-[58px] rounded-full transition-all"
          src={organizationAvatar}
        />
        {userAvatar && (
          <img
            alt="inviter"
            className="absolute inset-0 bottom-[-5px] top-[inherit] w-[28px] rounded-full shadow-md transition-all"
            src={userAvatar}
          />
        )}
      </div>
    </div>
  );
}

ImageAvatar.defaultProps = {};

export interface ImageAvatarProps {
  organizationAvatar?: string;
  userAvatar?: string;
}

/**
 * This component was generated from Figma with FireJet.
 * Learn more at https://www.firejet.io
 *
 * README:
 * The output code may look slightly different when copied to your codebase. To fix this:
 * 1. Include the necessary fonts. The required fonts are imported from public/index.html
 * 2. Include the global styles. They can be found in App.css
 *
 * Note: Step 2 is not required for tailwind.css output
 */
