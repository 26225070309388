import api from './api';

export async function allTransactions(skip: number): Promise<common.Transaction[] | errors.APIError> {
  const response = await api.get<any, any | errors.APIError>(
    '/store/transaction',
    {
      skip: skip || 0, sort: { modified_at: 'desc' },
    },
  );
  if (response.status === 200) {
    return response.data;
  }
  throw new Error(response.data?.msg);
}

export async function submitTransactionPin(
  id: string,
  pinSubmitted: string,
): Promise<common.Transaction | errors.APIError> {
  const response = await api.put<any, any | errors.APIError>(
    `/store/transactionrequest/${id}`,
    {
      pinSubmitted,
    },
  );
  if (response.status === 200) {
    return response.data;
  }
  throw new Error(response.data?.msg);
}
