import { navigate } from 'gatsby';
import React, { useReducer, useState, useEffect, useMemo, useCallback } from 'react';
import { useLocalStorage } from 'usehooks-ts';
import { me } from 'services/auth';
import { ISession } from 'business/models/session';
import { IUser, User } from 'business/models/user';

export type Action =
  | { type: 'session' }
  | { type: 'busy' }
  | { type: 'logout' }
  | { type: 'signin' };

export type Dispatch = (action: Action) => void;
export type State = { session: ISession | null; loading: boolean };

export const AuthContext = React.createContext<
  { state: State; dispatch: Dispatch; refresh: Function; logout: Function } | undefined
>(undefined);

function authReducer(state, action) {
  switch (action.type) {
    case 'session':
      return { ...state, session: action.session };
    case 'busy':
      return { ...state, loading: action.loading };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

export interface AuthProps {
  children?: any;
  location;
}

export function AuthProvider({ children, location }: AuthProps) {
  const [session, setSession] = useLocalStorage<ISession>('vsn', {} as ISession);
  const [authSession, setAuthSession] = useState<ISession>(null);
  const [state, dispatch] = useReducer(authReducer, { session, loading: true });
  const [desiredLocation, setDesiredLocation] = useState('');
  const [loading, setLoading] = useState(false);

  const logout = () => {
    setSession(null);
    setAuthSession(null);
  };
  const refresh = useCallback<() => Promise<{ user: IUser }>>(async (): Promise<{
    user: IUser;
  }> => {
    if (loading) return { user: null };
    setLoading(true);
    const resp = await me();
    const user = Object.assign(new User(), session.data, resp);
    const sess = Object.assign(session, { data: user });
    setAuthSession(sess);
    setSession(sess);
    setLoading(false);
    return { user };
  }, [loading, session, setSession]);

  React.useEffect(() => {
    const currentPath = (location.pathname as string).replace(/\/$/, '');
    if (currentPath.includes('auth') || currentPath.endsWith('member/invite')) {
      return;
    }
    if (!session?.token) {
      navigate('/auth/logout');
    }
  }, [location.pathname, session]);

  useEffect(() => {
    const run = async () => {
      try {
        await refresh();
      } catch (error) {}
    };
    if (session?.token) run();
  }, []);

  useEffect(() => {
    if (!session) {
      setAuthSession(null);
    } else {
      const user = Object.assign(new User(), session.data);
      setAuthSession(Object.assign(session, { data: user }));
    }
  }, [session]);

  const goBusiness = async () => {
    setDesiredLocation('start');
    navigate('/start/business', { replace: true });
  };

  useEffect(() => {
    const currentPath = (location.pathname as string).replace(/\/$/, '');
    if (authSession) {
      if (currentPath.includes('logout')) {
        setDesiredLocation('auth');
        navigate('/auth/signin', { replace: true });
      } else if (currentPath.endsWith('member/invite')) {
        dispatch({ type: 'busy', loading: false });
      } else if (!authSession.data?.id) {
        if (!currentPath.includes('auth')) {
          setDesiredLocation('auth');
          navigate('/auth/signin', { replace: true });
        }
      } else if (!authSession.data.org) {
        goBusiness();
      } else if (currentPath.includes('auth') || currentPath.includes('start')) {
        navigate('/', { replace: true });
      }
    }
  }, [authSession, location.pathname]);
  const value = useMemo(
    () => ({ state: { session: authSession, loading }, dispatch, refresh, logout }),
    [authSession, refresh, logout, loading]
  );

  useEffect(() => {
    if (desiredLocation.length === 0) return;
    if (location.pathname.includes(desiredLocation)) {
      dispatch({ type: 'busy', loading: false });
    }
  }, [location, desiredLocation]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
};
