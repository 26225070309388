import React from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
import tw from 'twin.macro';

export interface MemberItemProps {}

export const MemberItem: React.FC<MemberItemProps> = ({ children }) => {
  return (
    <div>{ children }</div>
  );
}
