import { IStorefront } from './storefront';

export interface BannerData {
  filename: string;
  id: string;
  url: string;
}

export interface BannerMedia {
  category: string;
  created_at: string;
  data: BannerData;
  id: string;
  user_id: string;
}

export interface IOrganization {
  id: string;
  name: string;
  email: string;
  description?: string;
  banner?: string | {};
  banner_file?: {};
  user_id: string;
  owner_user: User;
  banner_media?: BannerMedia;
  storefront?: IStorefront;
}

export interface IMember {
  name: string;
  email?: string;
  phone?: string;
}

export class Organization implements IOrganization {
  id: string;

  name: string;

  email: string;

  description?: string | undefined;

  banner?: string | {} | undefined;

  banner_file?: {} | undefined;

  user_id: string;

  owner_user: User;

  banner_media?: BannerMedia | undefined;

  get banner_url(): string {
    if (this.banner_media) return this.banner_media.data.url;
    return this.banner as string;
  }
}
