import { Profile } from 'business/models/user';
import api from './api';

export async function createProfile(profile: Profile): Promise<Profile | errors.APIError> {
  const response = await api.post<any, Profile | errors.APIError>('/store/profile', profile);
  if (response.status === 200) {
    return response.data as Profile;
  }
  throw new Error(response.data.msg);
}
export async function editProfile(
  id: string,
  profile: Profile
): Promise<Profile | errors.APIError> {
  const response = await api.put<any, Profile | errors.APIError>(`/store/profile/${id}`, profile);
  if (response.status === 200) {
    return response.data as Profile;
  }
  throw new Error(response.data.msg);
}
