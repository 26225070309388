import React, { useCallback, useEffect, useState } from 'react';
import { ProfileScreen } from 'app/features/user/profile-screen';
import { AccountScreen } from 'app/features/user/account-screen';
import { useAuth } from 'context/auth';
import { createProfile, editProfile } from 'services/profile';
import uploadPhoto from 'services/upload';
import toast from 'react-hot-toast';
import { createNamespace, editNamespace } from 'services/namespace';
import { Tabs, TabList, TabPanel, useTabsContext, Tab } from 'react-tabs';
import clsx from 'clsx';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  allAccounts,
  createAccount,
  defaultAccount,
  deleteAccount,
  fetchBanks
} from 'services/useraccount';

export default function Profile() {
  const [selectedTabIndex, setSelectedTabIndex] = useState(-1);

  const handleTabSelection = (index) => {
    setSelectedTabIndex(index);
  };
  const { refresh, state } = useAuth();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (!state.session) return;
    const run = async () => {
      try {
        refresh();
      } catch (error) {}
    };
    run();
  }, []);

  const onSubmitPersonalForm = useCallback(
    async (data) => {
      setLoading(true);
      try {
        if (state?.session?.data.profile) {
          await editProfile(state?.session?.data.profile?.id, data);
        } else {
          await createProfile(data);
        }
        refresh();
      } catch {
        setLoading(false);
      }
      setLoading(false);
    },
    [refresh, state?.session]
  );

  const onSubmitNamespaceForm = useCallback(
    async ({ namespace }) => {
      setLoading(true);
      try {
        if (state?.session?.data.namespace) {
          await toast.promise(editNamespace(state?.session?.data.namespace.id, namespace), {
            loading: 'saving username',
            success: 'username saved',
            error: (err) => `${err.message}`
          });
        } else {
          await toast.promise(createNamespace(namespace), {
            loading: 'saving username',
            success: 'username saved',
            error: (err) => `${err.message}`
          });
        }
      } catch (error) {}
      setLoading(false);
    },
    [refresh, state?.session]
  );

  const onSubmitProfileForm = useCallback(
    async ({ avatar }) => {
      if (avatar?.length) {
        const file = avatar[0];
        try {
          await toast.promise(
            uploadPhoto(state?.session?.data?.id, file.type, 'user', 'file', file, null),
            {
              loading: 'uploading avatar',
              success: 'uploaded avatar',
              error: (e) => e.message
            }
          );
          refresh();
        } catch (error) {
          setLoading(false);
        }
      }
    },
    [refresh, state?.session]
  );
  const tabs = ['Profile', 'Accounts'];
  useEffect(() => {
    const name = window.location.hash.substring(1);
    const hashIndex = tabs.findIndex((v) => v === name);
    if (hashIndex >= 0 && hashIndex < tabs.length) {
      setSelectedTabIndex(hashIndex);
    }
  }, []);

  useEffect(() => {
    const handleHashChange = () => {
      const name = window.location.hash.substring(1);
      const hashIndex = tabs.findIndex((v) => v === name);
      if (hashIndex >= 0 && hashIndex < tabs.length) {
        setSelectedTabIndex(hashIndex);
      }
    };

    window.addEventListener('hashchange', handleHashChange);

    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, []);

  const accounts = useQuery({
    queryKey: ['accounts'],
    queryFn: allAccounts
  });

  const banks = useQuery({
    queryKey: ['banks'],
    queryFn: fetchBanks
  });

  const newAccount = useMutation({
    mutationFn: createAccount,
    onMutate: () => {
      toast.loading('creating account');
    },
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
    },
    onSuccess: (data) => {
      toast.dismiss();
      toast.success('Account created');
      accounts.refetch();
    }
  });

  const setDefaultAccount = useMutation({
    mutationFn: defaultAccount,
    onMutate: () => {
      toast.loading('setting default account');
    },
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
    },
    onSuccess: (data) => {
      toast.dismiss();
      toast.success('Default account has been set');
      accounts.refetch();
    }
  });

  const removeAccount = useMutation({
    mutationFn: deleteAccount,
    onMutate: () => {
      toast.loading('removing account');
    },
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
    },
    onSuccess: (data) => {
      toast.dismiss();
      toast.success('Account removed');
      accounts.refetch();
    }
  });

  return (
    <Tabs selectedIndex={selectedTabIndex} onSelect={handleTabSelection} className="bg-base-100">
      <TabList className="tabs tab-lg  mx-10 mb-5">
        {tabs.map((name, ix) => (
          <Tab key={name}>
            <a
              href={`#${name}`}
              className={clsx('tab-lg tab tab-bordered', selectedTabIndex === ix && 'tab-active')}>
              {name}
            </a>
          </Tab>
        ))}
      </TabList>
      <TabPanel className="px-12">
        <ProfileScreen
          user={state?.session?.data}
          onSubmitPersonalForm={onSubmitPersonalForm}
          onSubmitNamespaceForm={onSubmitNamespaceForm}
          onSubmitProfileForm={onSubmitProfileForm}
          loading={loading}
        />
      </TabPanel>
      <TabPanel className="px-12">
        <AccountScreen
          onNewAccount={newAccount.mutate}
          accounts={accounts.data}
          banks={banks.data}
          onSetDefault={setDefaultAccount.mutate}
          onRemove={removeAccount.mutate}
          busy={newAccount.isLoading || accounts.isLoading || setDefaultAccount.isLoading}
        />
      </TabPanel>
    </Tabs>
  );
}
