import { useAuth } from 'context/auth';
import { useOrganization } from 'context/organization';
import { navigate } from 'gatsby';
import React from 'react';

export default function Logout() {
  const { logout } = useAuth();
  const { clear } = useOrganization();
  React.useEffect(() => {
    logout();
    clear();
    navigate('/auth/signin');
  }, [logout]);
  return <div />;
}
