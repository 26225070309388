/* eslint-disable camelcase */
import { IStorefront } from 'business/models/storefront';
import api from './api';

export async function storeFronts(title?: string): Promise<{ data: IStorefront[] }> {
  const url = '/store/storefront';
  const response = await api.get<{ data: IStorefront[] }>(url);
  if (response.status === 200) {
    return response.data;
  }
  throw new Error(response.data?.msg || 'failed');
}
