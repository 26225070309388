import * as React from 'react';
import { navigate } from 'gatsby';
import { FormNext, MailOption } from 'grommet-icons';
import { useLocalStorage } from 'usehooks-ts';
import {
  Image,
  Box,
  Heading,
  Paragraph,
  Form,
  TextInput,
  FormField,
  Text,
  Spinner,
  MaskedInput,
  Anchor,
  CheckBox
} from 'grommet';
import { useState } from 'react';
import { Background } from 'components';
import tw from 'twin.macro';
import api from 'services/api';
import Toast from 'components/Toast';
import { Button } from 'app/design/Button';
import { PhoneInput } from 'app/design/PhoneInput';
import logo from '../../images/Logo-asset-4.png';

const emailMask = [
  {
    regexp: /^[\w\-_.]+$/,
    placeholder: 'example'
  },
  { fixed: '@' },
  {
    regexp: /^[\w]+$/,
    placeholder: 'my'
  },
  { fixed: '.' },
  {
    regexp: /^[\w]+$/,
    placeholder: 'com'
  }
];

const RightSection = tw(Box)`lg:block hidden w-full`;

export default function Register() {
  const [lastName, setLastName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [Email, setEmail] = useState('');
  const [Password, setPassword] = useState('');
  const [phone, setPhone] = useState('');
  // const [signature, setSignature] = useState("")
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const [code, setCode] = useState('');
  const [session, setSession] = useLocalStorage<common.Session>('vsn', {} as common.Session);

  async function submit() {
    setLoading(true);
    const resp = await api.post<common.Session>('/auth/register', {
      Email,
      Password,
      firstName,
      lastName,
      phone
    });
    if (resp.status === 200) {
      setSession(resp.data);
      api.setHeader('Authorization', `bearer ${resp.data.token}`);
      navigate('/');
    } else {
      setCode('');
      if (resp.data?.msg) {
        setError(resp.data.msg);
      } else {
        setError('Please check your internet connection');
      }
      setLoading(false);
    }
    setCode('');
    setLoading(false);
  }

  return (
    <>
      <div className="flex h-screen flex-col bg-gray-200">
        <div className="mx-auto w-full py-8 px-2 md:w-1/2 lg:w-5/12 lg:p-8">
          <div className="rounded-lg bg-white p-8">
            <Box responsive align="start" justify="start" direction="column">
              <Heading level={2}>Create an account</Heading>
              <Paragraph size="medium" fill={false}>
                Get into your account and access all your wallets
              </Paragraph>
            </Box>
            <Form onSubmit={submit}>
              <div className="flex flex-col gap-4 py-4">
                <FormField label="First Name" name="first_name" required disabled={loading}>
                  <TextInput
                    plain={false}
                    placeholder="Enter your first name"
                    type="text"
                    name="first_name"
                    value={firstName}
                    onChange={(event) => setFirstName(event.target.value)}
                  />
                </FormField>
                <FormField label="Last Name" name="last_name" required disabled={loading}>
                  <TextInput
                    plain={false}
                    placeholder="Enter your last name"
                    type="text"
                    name="last_name"
                    value={lastName}
                    onChange={(event) => setLastName(event.target.value)}
                  />
                </FormField>

                <FormField label="Phone Number" name="phone" required disabled={loading}>
                  <PhoneInput value={phone} onChange={(v) => setPhone(v)} />
                  <TextInput type="hidden" name="phone" value={phone} />
                </FormField>
                <FormField label="Email" name="email" required disabled={loading}>
                  <MaskedInput
                    icon={<MailOption />}
                    mask={emailMask}
                    plain={false}
                    placeholder="Enter your email"
                    value={Email}
                    name="email"
                    type="email"
                    onChange={(event) => setEmail(event.target.value)}
                  />
                </FormField>
                <FormField label="Password" name="password" required disabled={loading}>
                  <TextInput
                    plain={false}
                    placeholder="Enter your password"
                    type="password"
                    name="password"
                    value={Password}
                    onChange={(event) => setPassword(event.target.value)}
                  />
                </FormField>

                <FormField label="" name="signature" required disabled={loading}>
                  <CheckBox
                    // checked={checked}
                    name="signature"
                    label={
                      <span className="text-sm">
                        By providing the information above, I hereby certify to the best of my
                        knowledge that the information provided above is correct.
                      </span>
                    }
                  />
                </FormField>
              </div>
              <Button
                className="btn btn-primary w-full"
                fill="horizontal"
                label="Create account"
                active={false}
                primary
                type="submit"
                disabled={loading === true}
                icon={loading ? <Spinner /> : null}
              />
            </Form>
            <Box
              direction="row"
              fill="horizontal"
              justify="center"
              align="center"
              pad={{ vertical: 'medium' }}>
              <Text size="small" color="dark-2">
                Already have an account?
                <Anchor
                  size="small"
                  color="dark-2"
                  label="Log in"
                  href="/auth/signin"
                  disabled={loading === true}
                  margin={{ left: 'xsmall' }}
                />
              </Text>
            </Box>
          </div>
        </div>
      </div>
      <Toast
        status="warning"
        visible={error.length > 0}
        onClose={() => setError('')}
        title="Sign In failed"
        message={error}
      />
    </>
  );
}
