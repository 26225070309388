import React from 'react';

import {
  ColumnDef,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable
} from '@tanstack/react-table';

import { Select } from 'grommet';
import { Member } from 'business/models/member';
import Trash from 'app/icons/TrashIcon';
import { MemberInfoCell } from '../MemberItem';
import { Button } from '../Button';
import 'twin.macro';

export interface MemberListProps {}

const columnHelper = createColumnHelper<Member>();

const baseColumns = [
  columnHelper.accessor('role', {
    header: 'Role',
    cell: (info) => (
      <div className="w-36 lg:w-48">
        <Select name="role" value={info.getValue()} options={['teller']} />
      </div>
    )
  }),
  columnHelper.accessor('status', {
    header: 'Status'
  }),
  columnHelper.accessor('created_at', {
    header: 'Created',
    cell: (info) => new Date(info.getValue()).toLocaleDateString('en-US')
  })
];

export function MemberList({ data, onCopiedInviteURL, onRemoveMember }) {
  // const rerender = React.useReducer(() => ({}), {})[1];
  const columns = React.useMemo<ColumnDef<Member>[]>(
    () => [
      columnHelper.display({
        id: 'member',
        header: 'Member',
        cell: (props) => {
          const { phone, status, pull, user } = props.row.original;
          return (
            <MemberInfoCell
              status={status}
              phone={phone}
              user={user}
              inviteURL={pull?.shortLink}
              onCopiedInviteURL={onCopiedInviteURL}
            />
          );
        }
      }),
      ...baseColumns,
      {
        id: 'remove',
        cell: ({ row }) => (
          <Button
            plain
            label="remove"
            icon={<Trash />}
            onClick={() => onRemoveMember(row.original)}
          />
        )
      }
    ],
    []
  );
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel()
  });

  return (
    <div className="overflow-x-auto">
      <table
        className="table-zebra w-full border-separate rounded-lg border border-gray-200"
        style={{ borderSpacing: 0 }}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id} className="border-0 p-3 text-left">
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className="border border-l-0 border-r-0 border-t-0 border-b-gray-200 p-2 px-4">
                  {header.isPlaceholder
                    ? null
                    : flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id} className="hover:bg-gray-100 dark:hover:bg-gray-700">
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className="p-2 px-4">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
        <tfoot>
          {table.getFooterGroups().map((footerGroup) => (
            <tr key={footerGroup.id}>
              {footerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(header.column.columnDef.footer, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </tfoot>
      </table>
      <div className="h-4" />
      {/* <button onClick={() => rerender()} className="border p-2">
        Rerender
      </button> */}
    </div>
  );
}
