import {
  Text,
  Card,
  CardBody,
  Box,
  NameValuePair,
  Heading,
  CardFooter,
  Button,
  CardHeader,
  Grommet
} from 'grommet';
import { Add, AddCircle, Alert, CreditCard, Server } from 'grommet-icons/icons';
import React from 'react';
// import { styled } from 'nativewind';
import theme from '../theme';

// const Card = styled(Container);
export interface WalletTileProps {
  id: string;
  currency?: string;
  dummy?: boolean;
  Link;
  onAddCredit?;
  onEdit?;
  owner: string;
  paid: number;
  received: number;
  title: string;
  user: string;
  withActions?: boolean;
}

function Identifier({ title, subtitle, icon }) {
  return (
    <Box direction="row" gap="18px" align="start">
      <Box pad={{ vertical: 'xsmall' }}>{icon}</Box>
      <Box>
        <Text color="text-strong" size="medium" weight="bold">
          {title}
        </Text>
        <Text size="small" weight="bold">
          {subtitle}
        </Text>
      </Box>
    </Box>
  );
}

export const MiniWalletTile: React.FC<WalletTileProps> = ({
  title,
  wallet: { received, paid }
}) => (
  <Box
    background="brand"
    width="280px"
    margin={{ right: 'medium' }}
    round="small"
    pad={{ horizontal: 'medium', vertical: 'medium' }}>
    <Identifier
      icon={<CreditCard size="medium" color="text-strong" />}
      title={title}
      subtitle={
        <Box margin={{ top: 'medium' }}>
          <Text size="medium">{`${Intl.NumberFormat('en-US').format(
            (received - paid) / 100
          )} credits`}</Text>
          <Text size="xsmall">{`NGN ${Intl.NumberFormat('en-US').format(
            (received - paid) / 100
          )}`}</Text>
        </Box>
      }
    />
    {/* <CardFooter align="center" justify="start">
      <Alert />
      <Text weight="bold">Expired</Text>
    </CardFooter> */}
  </Box>
);

export function WalletTile({
  dummy,
  user,
  onEdit,
  onAddCredit,
  Link,
  currency,
  withActions,
  ...rest
}: WalletTileProps) {
  const { id, received, paid, title, owner } = rest;
  return (
    <Card
      key={id}
      pad="0"
      alignSelf="start"
      margin={{ right: 'large' }}
      width={{ min: '280px', max: '340px' }}
      round="medium"
      background="dark-2"
      elevation="large"
      direction="column"
      animation={{ type: 'fadeIn', size: 'medium' }}>
      <CardHeader pad={{ horizontal: 'medium', top: 'medium' }}>
        <Text size="large" weight="bolder" wordBreak="break-word">
          {title}
        </Text>
        {withActions && (
          <Button
            label="Edit"
            onClick={() =>
              onEdit({
                id,
                received,
                paid,
                title,
                owner
              })
            }
          />
        )}
      </CardHeader>
      <CardBody
        align="start"
        pad={{ horizontal: 'medium', top: 'small', bottom: 'medium' }}
        gap="small">
        <NameValuePair
          name={<Text>Credits</Text>}
          children={
            <Box gap="xsmall">
              <Heading level="2" size="medium" margin="0" color="light-3">
                {Intl.NumberFormat('en-US').format((received - paid) / 100)}
              </Heading>
              <Text size="xsmall">Value</Text>
              <Text size="xsmall" weight="bold">
                {currency} {Intl.NumberFormat('en-US').format((received - paid) / 100)}
              </Text>
            </Box>
          }
        />
        {withActions && (
          <Button
            primary
            label="Add credit"
            icon={<AddCircle size="16px" />}
            size="xsmall"
            onClick={onAddCredit}
          />
        )}
      </CardBody>
      {withActions && (
        <CardFooter
          justify="stretch"
          direction="row"
          pad={{ horizontal: 'medium', vertical: 'small' }}
          background="dark-1"
          gap="medium"
          margin="0"
          round={{
            corner: 'bottom',
            size: 'small'
          }}>
          <Button
            as={Link}
            primary
            label="Send"
            plain={false}
            type="button"
            href={dummy ? null : `/disperse?id=${id}`}
            to={dummy ? null : `/disperse?id=${id}`}
          />
          <Button
            as={Link}
            secondary
            label="Receive"
            plain={false}
            type="button"
            href={dummy ? null : `/receive?id=${id}`}
            to={dummy ? null : `/receive?id=${id}`}
          />
        </CardFooter>
      )}
    </Card>
  );
}

WalletTile.defaultProps = {
  currency: 'NGN',
  withActions: false
};
