import { IMedia } from 'business/models/media';
import api from './api';
import { uploadPhoto } from './upload';

export async function createMedia(
  category: 'profile' | 'organization_profile' | 'organization_banner',
  file,
  progress
): Promise<{ media: IMedia; upload: any } | errors.APIError> {
  const response = await api.post<IMedia, errors.APIError>('/store/media', {
    category
  });
  const media = response.data as IMedia;
  if (response.status === 200) {
    const upload = await uploadPhoto(response.data.id, file.type, 'media', 'file', file, progress);
    return { media, upload };
  }
  throw new Error(response.data.msg);
}
