import React from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
import tw from 'twin.macro';
import { ISession } from 'business/models/session';
import { LogoIcon } from '../LogoIcon';

export interface NavbarProps {
  session?: ISession;
}

export function Navbar({ session }: NavbarProps) {
  return (
    <div className="navbar bg-base-100 p-0">
      <div className="flex-1">
        <a className="btn btn-ghost py-0 px-2 text-xl normal-case" href="/">
          <LogoIcon full />
        </a>
      </div>
      <div className="flex flex-row gap-2">
        <div className="form-control hidden">
          <input type="text" placeholder="Search" className="input input-bordered" />
        </div>
        <div className="dropdown dropdown-end">
          <a
            tabIndex={0}
            className="btn btn-ghost btn-circle avatar"
            href="/profile/#Profile"
            alt="your profile">
            <div className="w-10 rounded-full bg-gray-200">
              {session?.data.avatar?.url && <img alt="avatar" src={session.data.avatar.url} />}
            </div>
          </a>
          {/* <ul
            tabIndex={0}
            className="menu menu-compact dropdown-content bg-base-100 rounded-box mt-3 w-52 p-2 shadow">
            <li>
              <a className="justify-between" href="/profile/#Profile">
                Profile
                <span className="badge">New</span>
              </a>
            </li>
            <li>
              <a>Settings</a>
            </li>
            <li>
              <a href="/auth/logout">Logout</a>
            </li>
          </ul> */}
        </div>
      </div>
    </div>
  );
}

Navbar.defaultProps = { session: null };
