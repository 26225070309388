import React from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
import tw from 'twin.macro';

import InsightDemo from './insights_Demo.png';

export interface InsightCommingSoonProps {}

export function InsightCommingSoon() {
  return (
    <div className="mx-auto">
      <div className="prose prose-slate">
        <h1 className="text-lg font-bold">Insights - Comming Soon</h1>
        <span>Get smarter with actionable insights into activity in your business</span>
      </div>
      <div className="mx-auto rounded p-8">
        <img src={InsightDemo} alt="demo" />
      </div>
    </div>
  );
}
