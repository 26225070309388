import { superstructResolver } from '@hookform/resolvers/superstruct';
import { Button } from 'app/design/Button';
import { NamespaceForm } from 'app/design/NamespaceForm';
import { PersonalForm } from 'app/design/PersonalForm';
import { ProfileForm } from 'app/design/ProfileForm';
import { IUser } from 'business/models/user';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { object, string, min, size, nonempty } from 'superstruct';

export interface ProfileScreenProps {
  user: IUser;
  onSubmitPersonalForm: (data) => {};
  onSubmitProfileForm: (data) => {};
  onSubmitNamespaceForm: (data) => {};
  loading: boolean;
}

export function ProfileScreen({
  user,
  onSubmitPersonalForm,
  onSubmitProfileForm,
  onSubmitNamespaceForm,
  loading
}: ProfileScreenProps) {
  const { firstName, lastName, city, country, gender, postalCode, state, namespace } = user || {};
  const profileValues = {};
  const personalValues = {
    firstName,
    lastName,
    city,
    country,
    gender,
    postalCode,
    state,
    ...(user?.profile || {})
  };
  delete personalValues.avatar;
  const namespaceValues = {
    namespace: namespace?.name
  };
  const [avatar, setAvatar] = useState(user?.avatar?.url || '');
  const personalForm = useForm({ defaultValues: personalValues });
  const profileForm = useForm({ defaultValues: profileValues });

  const Namespace = object({
    namespace: size(string(), 3, 15)
  });

  const namespaceForm = useForm({
    defaultValues: namespaceValues,
    resolver: superstructResolver(Namespace)
  });

  useEffect(() => {
    if (!user) return;
    setAvatar(user?.avatar?.url ? user.avatar.url : '');
    personalForm.reset(personalValues);
    namespaceForm.reset(namespaceValues);
    profileForm.reset(profileValues);
  }, [personalForm.reset, user]);

  return (
    <div>
      <form onSubmit={profileForm.handleSubmit(onSubmitProfileForm)}>
        <ProfileForm
          register={profileForm.register}
          loading={loading}
          avatar={avatar}
          errors={profileForm.formState.errors}
        />
        <div className="hidden sm:block" aria-hidden="true">
          <div className="py-5">
            <div className="border-t border-gray-200" />
          </div>
        </div>

        <div className="flex justify-between bg-gray-50 px-4 py-3 sm:px-6">
          <div />
          <Button type="submit" className="btn btn-outline" label="Save" loading={loading} />
        </div>
      </form>
      <div className="hidden sm:block" aria-hidden="true">
        <div className="py-5">
          <div className="border-t border-gray-200" />
        </div>
      </div>
      <form onSubmit={namespaceForm.handleSubmit(onSubmitNamespaceForm)}>
        <NamespaceForm
          register={namespaceForm.register}
          loading={loading}
          errors={namespaceForm.formState.errors}
        />
        <div className="hidden sm:block" aria-hidden="true">
          <div className="py-5">
            <div className="border-t border-gray-200" />
          </div>
        </div>

        <div className="flex justify-between bg-gray-50 px-4 py-3 sm:px-6">
          <div />
          <Button type="submit" className="btn btn-primary" label="Save" loading={loading} />
        </div>
      </form>
      <div className="hidden sm:block" aria-hidden="true">
        <div className="py-5">
          <div className="border-t border-gray-200" />
        </div>
      </div>
      <form onSubmit={personalForm.handleSubmit(onSubmitPersonalForm)}>
        <PersonalForm
          register={personalForm.register}
          loading={loading}
          errors={personalForm.formState.errors}
        />
        <div className="hidden sm:block" aria-hidden="true">
          <div className="py-5">
            <div className="border-t border-gray-200" />
          </div>
        </div>

        <div className="flex justify-between bg-gray-50 px-4 py-3 sm:px-6">
          <div />
          <Button type="submit" className="btn btn-outline" label="Save" loading={loading} />
        </div>
      </form>
      {/* <div className="mt-10 sm:mt-0">
      <div className="md:grid md:grid-cols-3 md:gap-6">
        <div className="md:col-span-1">
        <div className="px-4 sm:px-0">
          <h3 className="text-lg font-medium leading-6 text-gray-900">Notifications</h3>
            <p className="mt-1 text-sm text-gray-600">
            Decide which communications you'd like to receive and how.
          </p>
        </div>
      </div>
        <div className="mt-5 md:col-span-2 md:mt-0">
          <form action="#" method="POST">
            <div className="overflow-hidden shadow sm:rounded-md">
            <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                <fieldset>
                  <legend className="sr-only">By Email</legend>
                  <div className="text-base font-medium text-gray-900" aria-hidden="true">
                  By Email
                </div>
                  <div className="mt-4 space-y-4">
                    <div className="flex items-start">
                      <div className="flex h-5 items-center">
                        <input
                        id="comments"
                          name="comments"
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                        />
                      </div>
                      <div className="ml-3 text-sm">
                      <label htmlFor="comments" className="font-medium text-gray-700">
                        Comments
                        </label>
                    <p className="text-gray-500">Get notified when someones posts a comment on a posting.</p>
                      </div>
                    </div>
                  <div className="flex items-start">
                      <div className="flex h-5 items-center">
                        <input
                        id="candidates"
                          name="candidates"
                        type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                        />
                      </div>
                      <div className="ml-3 text-sm">
                      <label htmlFor="candidates" className="font-medium text-gray-700">
                        Candidates
                        </label>
                    <p className="text-gray-500">Get notified when a candidate applies for a job.</p>
                      </div>
                    </div>
                  <div className="flex items-start">
                      <div className="flex h-5 items-center">
                        <input
                          id="offers"
                          name="offers"
                        type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                        />
                      </div>
                      <div className="ml-3 text-sm">
                      <label htmlFor="offers" className="font-medium text-gray-700">
                        Offers
                        </label>
                        <p className="text-gray-500">
                        Get notified when a candidate accepts or rejects an offer.
                      </p>
                      </div>
                    </div>
                </div>
                </fieldset>
                <fieldset>
              <legend className="contents text-base font-medium text-gray-900">Push Notifications</legend>
              <p className="text-sm text-gray-500">These are delivered via SMS to your mobile phone.</p>
                <div className="mt-4 space-y-4">
                    <div className="flex items-center">
                      <input
                      id="push-everything"
                        name="push-notifications"
                      type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      />
                  <label htmlFor="push-everything" className="ml-3 block text-sm font-medium text-gray-700">
                      Everything
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="push-email"
                        name="push-notifications"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      />
                  <label htmlFor="push-email" className="ml-3 block text-sm font-medium text-gray-700">
                      Same as email
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="push-nothing"
                      name="push-notifications"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      />
                  <label htmlFor="push-nothing" className="ml-3 block text-sm font-medium text-gray-700">
                      No push notifications
                      </label>
                  </div>
                  </div>
              </fieldset>
              </div>
            <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                <button
                  type="submit"
              className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
            Save
                </button>
              </div>
            </div>
        </form>
      </div>
      </div>
    </div> */}
    </div>
  );
}
