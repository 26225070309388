import React from 'react';
import 'twin.macro';

export default function Trash(props: TrashProps) {
  return (
    <div className="h-6 w-6" style={props.style}>
      <svg
        width="100%"
        height="100%"
        preserveAspectRatio="none"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M 4 6 C 3.448 6 3 6.448 3 7 C 3 7.552 3.448 8 4 8 V 6 Z M 20 8 C 20.552 8 21 7.552 21 7 C 21 6.448 20.552 6 20 6 V 8 Z M 11 11 C 11 10.448 10.552 10 10 10 C 9.448 10 9 10.448 9 11 H 11 Z M 9 17 C 9 17.552 9.448 18 10 18 C 10.552 18 11 17.552 11 17 H 9 Z M 15 11 C 15 10.448 14.552 10 14 10 C 13.448 10 13 10.448 13 11 H 15 Z M 13 17 C 13 17.552 13.448 18 14 18 C 14.552 18 15 17.552 15 17 H 13 Z M 5.997 6.917 C 5.951 6.367 5.467 5.958 4.917 6.003 C 4.367 6.049 3.958 6.533 4.003 7.083 L 5.997 6.917 Z M 6 19 H 7 C 7 18.972 6.999 18.945 6.997 18.917 L 6 19 Z M 18 19 L 17.004 18.917 C 17.001 18.945 17 18.972 17 19 H 18 Z M 19.997 7.083 C 20.042 6.533 19.633 6.049 19.083 6.003 C 18.533 5.958 18.049 6.367 18.004 6.917 L 19.997 7.083 Z M 8 7 C 8 7.552 8.448 8 9 8 C 9.552 8 10 7.552 10 7 H 8 Z M 10 3 V 2 V 3 Z M 14 3 V 2 V 3 Z M 14 7 C 14 7.552 14.448 8 15 8 C 15.552 8 16 7.552 16 7 H 14 Z M 4 8 H 20 V 6 H 4 V 8 Z M 9 11 V 17 H 11 V 11 H 9 Z M 13 11 V 17 H 15 V 11 H 13 Z M 4.003 7.083 L 5.003 19.083 L 6.997 18.917 L 5.997 6.917 L 4.003 7.083 Z M 5 19 C 5 19.796 5.316 20.559 5.879 21.121 L 7.293 19.707 C 7.105 19.52 7 19.265 7 19 H 5 Z M 5.879 21.121 C 6.441 21.684 7.204 22 8 22 V 20 C 7.735 20 7.48 19.895 7.293 19.707 L 5.879 21.121 Z M 8 22 H 16 V 20 H 8 V 22 Z M 16 22 C 16.796 22 17.559 21.684 18.121 21.121 L 16.707 19.707 C 16.52 19.895 16.265 20 16 20 V 22 Z M 18.121 21.121 C 18.684 20.559 19 19.796 19 19 H 17 C 17 19.265 16.895 19.52 16.707 19.707 L 18.121 21.121 Z M 18.997 19.083 L 19.997 7.083 L 18.004 6.917 L 17.004 18.917 L 18.997 19.083 Z M 10 7 V 4 H 8 V 7 H 10 Z M 10 4 V 4 L 8.586 2.586 C 8.211 2.961 8 3.47 8 4 H 10 Z M 10 4 V 4 V 2 C 9.47 2 8.961 2.211 8.586 2.586 L 10 4 Z M 10 4 H 14 V 2 H 10 V 4 Z M 14 4 V 4 L 15.414 2.586 C 15.039 2.211 14.53 2 14 2 V 4 Z M 14 4 V 4 H 16 C 16 3.47 15.789 2.961 15.414 2.586 L 14 4 Z M 14 4 V 7 H 16 V 4 H 14 Z"
          fill="#171F22"
        />
      </svg>
    </div>
  );
}

Trash.defaultProps = {
  style: {}
};

interface TrashProps {
  style: Object;
}
