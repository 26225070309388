import api from './api';

export async function createOtp(
  action: 'create_user',
  method: 'phone',
  value,
  origin: string
): Promise<common.OTPAction | errors.APIError> {
  const response = await api.post<any, common.OTPAction | errors.APIError>('/store/otpaction', {
    origin,
    action,
    method,
    value
  });
  if (response.status === 200) {
    return response.data as common.OTPAction;
  }
  throw new Error(response.data.msg);
}

export async function createInviteOtp(
  phone: string,
  member: string,
  origin: string
): Promise<common.OTPAction | errors.APIError> {
  const response = await api.post<any, common.OTPAction | errors.APIError>('/store/otpaction', {
    origin,
    action: 'create_user_for_member',
    method: 'phone',
    value: {
      phone,
      member
    }
  });
  if (response.status === 200) {
    return response.data as common.OTPAction;
  }
  throw new Error(response.data.msg);
}

export async function getOtp(id: string): Promise<common.OTPAction | errors.APIError> {
  const response = await api.get<any, common.OTPAction | errors.APIError>(`/store/otpaction/${id}`);
  if (response.status === 200) {
    return response.data as common.OTPAction;
  }
  throw new Error(response.data.msg);
}

export async function verifyOtp(
  id: string,
  code: string
): Promise<common.OTPResponse | errors.APIError> {
  const response = await api.post<any, common.OTPAction | errors.APIError>('/store/otpresponse', {
    otpaction_id: id,
    code,
    method: 'phone'
  });
  if (response.status === 200) {
    return response.data as common.OTPResponse;
  }
  throw new Error(response.data.msg);
}
