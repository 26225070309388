import React, { useState } from 'react';
import { navigate } from 'gatsby';
import { useAuth } from 'context/auth';
import { useMutation } from '@tanstack/react-query';
import { OrganizationSignup } from '../../components';
import { createOrganization } from '../../services/organization';

function Organization() {
  const { refresh } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const onCreate = useMutation({
    mutationFn: createOrganization,
    onSettled(data, error, variables, context) {
      console.log({ data, error, variables, context });
    },
    onMutate(variables) {
      setLoading(true);
    },
    onSuccess(data, variables, context) {
      setLoading(false);
      refresh();
    },
    onError(error, variables, context) {
      setLoading(false);
    }
  });
  return <OrganizationSignup onCreate={onCreate.mutate} busy={loading} error={onCreate.error} />;
}

export default Organization;
