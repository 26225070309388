import { Box, Heading } from 'grommet';
import React from 'react';

export default function error() {
  return (
    <Box justify="center" align="center" className="flex items-center justify-center">
      <Heading size="2xl">404 - Page not found</Heading>
    </Box>
  );
}
