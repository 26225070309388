import React from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
import { CopyToClipboard } from 'react-copy-to-clipboard';
import User, { IUser } from 'business/models/user';
import 'twin.macro';

export interface MemberInfoCellProps {
  status: any;
  photo?: string;
  phone: string;
  user?: IUser;
  inviteURL: string;
  onCopiedInviteURL;
  onSendInviteLink;
}

export function MemberInfoCell({
  status,
  user,
  phone,
  photo,
  inviteURL,
  onCopiedInviteURL,
  onSendInviteLink
}: MemberInfoCellProps) {
  const memberUser = Object.assign(new User(), user);
  return (
    <div className="flex flex-row items-center gap-4">
      {memberUser.avatar && (
        <div className="avatar">
          <div className="w-10 rounded-full">
            <img alt="" src={memberUser.avatar.url || ''} className=" rounded-full" />
          </div>
        </div>
      )}
      <div className="flex flex-col">
        <span className="text-lg font-bold">
          {memberUser?.name || memberUser?.verified_phone || phone}
        </span>
        {memberUser?.name && <span className="text-xs">{memberUser?.verified_phone || phone}</span>}
        <div className="flex flex-row">
          {status === 'pending' && (
            <div className="flex flex-col gap-2">
              <span className="label-text text-gray-400">waiting for member to accept</span>
              <CopyToClipboard text={inviteURL} onCopy={onCopiedInviteURL}>
                <button type="button" className="btn btn-primary btn-xs">
                  Copy invite link
                </button>
              </CopyToClipboard>
            </div>
          )}
          {onSendInviteLink && (
            <button type="button" className="btn btn-xs btn-outline" onClick={onSendInviteLink}>
              Send invite link
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
