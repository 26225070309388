module.exports = [{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-google-gtag@5.8.0_jaoqzzfbpumcku6ctf5e6g4hxi/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-1LS6HHVFCM"]},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-manifest@5.8.0_gatsby@5.8.1/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"changemida","short_name":"changemida","start_url":"/","background_color":"#0231BF","theme_color":"#0231BF","display":"standalone","icon":"src/images/icon.png","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"ac71dc462701704a737c3b9aed26d6a3"},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-styled-components@6.8.0_uwe7wgbtahwifvfmydxr44bbxu/node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-use-query-params@1.0.1_znaoimiodqvbjcnzjrievmeb6q/node_modules/gatsby-plugin-use-query-params/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/.pnpm/@sentry+gatsby@7.47.0_gatsby@5.8.1+react@18.2.0/node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://84ed3eb297bd4aec8854f269e09394c1@o52068.ingest.sentry.io/1515091","sampleRate":0.7},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby@5.8.1_ygqkwb4gg3aean7xjfdauovyqq/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
