import React from 'react';

import { InsightCommingSoon } from 'app/design/InsightCommingSoon';

import 'twin.macro';

export default function insight() {
  return (
    <div className="px-10">
      <InsightCommingSoon />
    </div>
  );
}
