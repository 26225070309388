import { IOrganization } from 'business/models/organization';
import { IWallet } from 'business/models/wallet';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { organizationWallets } from 'services/wallet';
import { useAuth } from './auth';

export type State = { null; loading: boolean };

export const OrganizationContext = React.createContext<
  | {
      organization?: IOrganization | null;
      wallets?: IWallet[];
      refresh: Function;
      clear: Function;
      loading?: boolean;
      id?: string;
    }
  | undefined
>(undefined);

export interface OrganizationProps {
  children?: any;
}

export function OrganizationProvider({ children }: OrganizationProps) {
  const { state: authState } = useAuth();
  const [organization, setOrganization] = useState(null);
  const [id, setID] = useState(authState.session?.data.org?.id);
  const [wallets, setWallets] = useState(null);

  const clear = () => {
    setOrganization(null);
  };

  const refresh = useCallback(async () => {
    const orgId = authState.session?.data?.isOwner ? null : id;
    try {
      const resp = await organizationWallets(orgId);
      setWallets(resp.data);
    } catch {}
  }, [authState.session?.data, id]);

  useEffect(() => {
    if (!organization && !!authState.session?.data.org) {
      refresh();
    }
  }, [organization, refresh, authState.session?.data.org]);

  useEffect(() => {
    if (!authState.session?.data.org?.id) return;
    if (organization) return;
    const org = authState.session?.data.org;
    setOrganization({
      ...org,
      banner: org?.banner_media?.data?.url || org?.banner,
      banner_file: {
        files: [org?.banner_media?.data?.url || org?.banner || '']
      }
    });
    setID(authState.session?.data.org.id);
  }, [authState.session?.data.org, authState.session?.data.org?.id, organization]);

  const data = useMemo(
    () => ({ organization, id, wallets, refresh, clear }),
    [organization, wallets, refresh, id]
  );
  return <OrganizationContext.Provider value={data}>{children}</OrganizationContext.Provider>;
}

export const useOrganization = () => {
  const context = React.useContext(OrganizationContext);
  if (context === undefined) {
    throw new Error('useOrganization must be used within a OrganizationProvider');
  }
  return context;
};
