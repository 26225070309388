// enum CharacterType { Warrior, Mage, Healer }

import { INamespace } from './namespace';
import { IOrganization } from './organization';
import { IWallet } from './wallet';

export interface Profile {
  avatar?: any;
  created_at: string;
  dob: Date;
  firstName: string;
  lastName: string;
  gender: string;
  id: string;
  name: string;
  phone: string;
  user: string;
}

export type IUser = {
  id: string;
  verified_phone?: string;
  organization?: IOrganization;
  member_organization?: IOrganization[];
  namespace?: INamespace;
  Email: string;
  avatar?: { url: string };
  balance?: any;
  created_at: string;
  linkedAccounts: any[];
  modified_at: string;
  name: string;
  firstName: string;
  lastName: string;
  reports?: any;
  rewards?: any;
  customers?: number;
  org: IOrganization | undefined | null;
  profile?: Profile;
  isOwner: boolean;
  organization_wallets?: IWallet[];
};

export class User implements IUser {
  Email: string;

  avatar?: any;

  balance?: any;

  created_at: string;

  linkedAccounts: any[];

  modified_at: string;

  firstName: string;

  lastName: string;

  reports?: any;

  rewards?: any;

  customers?: number | undefined;

  id: string;

  organization: IOrganization;

  profile?: Profile;

  member_organization?: IOrganization[];

  get name(): string {
    if (this.profile) return this.profile.firstName;
    return this.firstName;
  }

  get org(): IOrganization | undefined | null {
    if (this.member_organization) {
      return this.member_organization[0];
    }
    if (this.organization) {
      return this.organization[0];
    }
    return null;
  }

  get isOwner(): boolean {
    return this.org?.user_id === this.id;
  }
}

export default User;
